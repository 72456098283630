import 'flowbite';
import { Route, Router, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from "./pages/Layout";
import 'react-rangeslider/lib/index.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getAccountTokens, setTokenLoader, updateAuth } from "./store/slices/authSlice";
import { getTokens, setSwapTokenLoader } from "./store/slices/swapSlice";
import { useEffect } from "react";
import { getCryptocurrency } from "./store/slices/marketSlice";
import { Dashboard } from "./pages/Dashboard";
import { Login } from './pages/Login';
import PrivateRoutes from './utils/PrivateRoutes';
import { Register } from './pages/Register';
import { parseJwt } from './helper';
import { SplashScreen } from './pages/SplashScreen';
import { SignupCodes } from './pages/SignupCodes';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const app = useSelector((x) => x.app);
  let isLoggedIn = false; 

  if (localStorage.getItem('velar')) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('velar')).accessToken;
      const data = parseJwt(accessToken);
      isLoggedIn = true;
      dispatch(updateAuth({ isLoggedIn: true, authData: data }));
    } catch (error) {
      dispatch(updateAuth({ isLoggedIn: false, authData: null }));
    }
  } else {
    dispatch(updateAuth({ isLoggedIn: false, authData: null }));
  }


  return (
    <div className="velar-insights relative">
        <Layout>
          {(app.splash && isLoggedIn) && (
            <SplashScreen />
          )}
            <Routes>
                <Route element={<PrivateRoutes />} >
                  <Route path='/signup-codes' element={<SignupCodes />} />
                  <Route path="/" element={ <Dashboard /> } />
                </Route>
                <Route path="/login" element={ <Login /> } />
                <Route path="/register" element={ <Register /> } />
            </Routes>
        </Layout>
      <ReactTooltip id="tooltip" place="bottom" />
    </div>
  );
}

export default App;
