import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CodeService from '../services/CodeService';
import { Table } from 'flowbite-react';
import Loader from '../components/Loader';
import { copyToClipboard } from '../helper';

export const SignupCodes = (props) => {
    const [loader, setLoader] = useState(false);
    const [codes, setCodes] = useState([]);
    const [filterdCodes, setFilterdCodes] = useState([]);
    const [search, setSearch] = useState('');
    const app = useSelector((x) => x.app);
    const auth = useSelector((x) => x.auth);

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        const _filterdCodes = codes.filter((x) => {
            if (x.email.toLowerCase().indexOf(search) > -1 || x.status.toLowerCase().indexOf(search) > -1 || x.code.toLowerCase().indexOf(search) > -1) {
                return x;
            }
        }).filter(Boolean);
        setFilterdCodes(_filterdCodes);
    }, [search])

    const getData = async () => {
        setLoader(true)
        const codes = (await CodeService.getCodes()).map((x) => {
            x.status = x.active ? 'Active' : 'InActive';
            return x;
        });
        setCodes(codes);
        setFilterdCodes(codes);
        setLoader(false)
    }

    const generate = async (e) => {
        setLoader(true)
        await CodeService.generateCode();
        await getData();
    }

    const deleteCode = async (id) => {
        setLoader(true)
        await CodeService.deleteCode(id);
        await getData();
    }

    if (app.splash || !auth?.authData?.is_super_admin) {
        return '';
    }

    return (
        <div className='dashboard mt-20 p-5 rounded-md'>
            <h1 className='text-gray text-lg font-bold'></h1>
            <div className="widget-header mb-6 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Signup Codes</h1>
                    <p className='text-gray text-sm'>Total codes: { codes.length }, Active Codes: { codes.filter((x) => x.active).length }, InActive Codes { codes.filter((x) => !x.active).length }</p>
                </div>
                <div className="right flex flex-row justify-between">
                    {loader && (
                        <div className='ml-3'>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    )}
                    <div>
                        <button onClick={generate} className="mr-2 text-white bg-[#F2F2F2] hover:bg-white shadow focus:ring-4 focus:outline-none focus:ring-[#F2F2F2]-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                            <span style={{ color: '#555' }}>Generate</span>
                        </button>
                        {/* <button type="button" class="border text-gray bg-white hover:bg-white font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">Generate</button> */}
                    </div>
                    <div className='w-[300px]'>
                        <input value={search} onChange={(e) => setSearch(e.target.value)} type="search" id="search" className="border text-gray text-sm rounded-lg block w-full p-2.5" placeholder="Search ..." required />
                    </div>
                </div>
            </div>
            <div className='w-full overflow-auto'>
                <table style={{ tableLayout: 'fixed' }} className='shadow rounded-full mt-4 w-full text-sm text-left text-gray text-gray'>
                    <thead className='text-xs text-gray-700 uppercase bg-white'>
                        <tr>
                            <th scope="col" class="px-6 py-4">Email</th>
                            <th scope="col" class="px-6 py-4">Code</th>
                            <th scope="col" class="px-6 py-4">Status</th>
                            <th style={{ width: 200 }} scope="col" class="px-6 py-4 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterdCodes.map((code, index) => {
                            return (
                                <tr class="bg-white border-b dark:bg-gray-900" style={{ borderColor: '#eee' }} key={index}>
                                    <td className="px-6 py-4">{code.email}</td>
                                    <td className="px-6 py-4">
                                        <div className='flex items-center'>
                                            {code.code}
                                            <button onClick={() => copyToClipboard(code.code) } className='btn-copy-text ml-1'>
                                                <img className='ml-1' src='/assets/icons/icon-copy-text.svg' alt="" />
                                            </button>
                                        </div>
                                    </td>
                                    { code.active ? (
                                        <td style={{ color: 'green' }} className="px-6 py-4">Active</td>
                                    ) : (
                                        <td style={{ color: 'red' }} className="px-6 py-4">In Active</td>
                                    ) }
                                    <td style={{ width: 100 }} className='text-center'>
                                        <button onClick={() => deleteCode(code._id)} className="mr-2 text-white bg-[#ff5e5e] hover:bg-[#a40c0c] shadow focus:ring-4 focus:outline-none focus:ring-[#F2F2F2]-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                                            <span>Delete</span>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>          
        </div>
    )
}