import { isMobile } from '@stacks/connect';
import http, { swapHttp } from '../utils/axiosProvider'

class SwapService {
    async getTokens() {
        const result = await swapHttp.get('/swap/tokens');
        let data = result.data;
        data.message = data.message.map((record) => {
            record.price = Number(record.price || 0);
            return record;
        })
        return data;
        // return Promise.resolve({
        //     "statusCode": 200,
        //     "message": [
        //       {
        //         "symbol": "VELAR",
        //         "name": "Velar",
        //         "contractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.velar",
        //         "imageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/velar.jpg",
        //         "price": 0,
        //         "decimal": "u6",
        //         "tokenDecimalNum": 1000000,
        //         "assetName": "velar"
        //       },
        //       {
        //         "symbol": "wSTX",
        //         "name": "Wrapped STX",
        //         "contractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx",
        //         "imageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg",
        //         "price": 0.5547681463331441,
        //         "decimal": "u6",
        //         "tokenDecimalNum": 1000000,
        //         "assetName": "stx"
        //       }
        //     ]
        //   });
    }

    async saveTransaction (data) {
        const payload = {
            "publicAddress": data.publicAddress,
            "fromTokenSymbol": data.fromTokenSymbol,
            "fromTokenName": data.fromTokenName,
            "fromTokenQuantity": String(data.fromTokenQuantity),
            "fromContractAddress": String(data.fromContractAddress),
            "toTokenSymbol": data.toTokenSymbol,
            "toTokenName": data.toTokenSymbol,
            "toTokenQuantity": String(data.toTokenQuantity),
            "toContractAddress": data.toContractAddress,
            "slippage": String(data.slippage),
            "fee": String(data.fee),
            "transactionHash": data.transactionHash,
            "status": 'success',
            "exchangeRate": 1,
            "poolId": String(data.poolId),
            "transactionHash": data.transactionHash,
            "txResponse": data.txResponse,
            "walletInUse": data.walletInUse || 'hiro',
            "txType": data.txType,
            "deviceType": isMobile() ? 'mobile' : 'web',
        };

        await swapHttp.post('/swap/transactions', payload);
    }
}

export default (new SwapService());