import './linewithline.chart';
import { Chart as ChartJs, LineProps, Filler, CategoryScale, Tooltip, LinearScale , PointElement, LineElement } from 'chart.js';
import React from "react";
import { Line, Chart } from "react-chartjs-2";
import { commarize } from '../../helper';
import { LineDefaultOptions } from './defaultOptions';
ChartJs.register(CategoryScale, LinearScale, Filler, Tooltip, PointElement, LineElement);


export const VLineChart = (props) => {
  const labels = props.labels || [];
  const records = props.records || [];
  const dollar = props.dollar === true  ? '$' : '';
  const options = props.options || LineDefaultOptions({ dollar }).options;
  const datasetOptions = props.datasetOptions || LineDefaultOptions({ dollar }).datasetOptions;
  const rootClassName = props.rootClassName || 'h-[380px]';

  const data = {
    labels: labels,
    datasets: [
      {
        ...datasetOptions,
        data: records
      }
    ]
  };

  return (
    <div className={rootClassName}>
        <Chart type='linewithline' data={data} options={options} />
    </div>
    );
}