import { useEffect, useState } from 'react'
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { VLineChart } from '../Charts/VLineChart'
import { LineDefaultOptions, SimpleLineOptions } from '../Charts/defaultOptions'
import { useDispatch, useSelector } from 'react-redux'
import { getAccumelatedStats } from '../../store/slices/statsSlice'
import Loader from '../Loader'
import { sum } from 'lodash'
import { commarize } from '../../helper'

export default function AccumelatedStatsWidget(props) {
    const [ type, setType ] = useState({ label: 'Total Volume', key: 'volume' })
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    const tx = stats.accumelatedTransactions;


    useEffect(() => {
        dispatch(getAccumelatedStats({ type: type.key }));
    }, [type.key, filter.calendar?.range, filter.refresh])

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-4 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Cumulative Stats</h1>
                    <p className='text-gray text-sm'>Total volume and fees across all active pools</p>
                </div>
                <div className="right flex flex-row justify-between items-center">
                    { tx.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    ) }
                    <ElipseIcon />
                </div>
            </div>
            <div className='actions mb-4'>
                <button id="sp-tacc" data-dropdown-toggle="spacc-dropdown" className="w-full justify-between border-slate-200 text-white bg-white border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                    <div className='flex flex-row gap-2 items-center'>
                        <span style={{ color: '#555' }}>{ type.label }</span>
                    </div>
                    <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <div id="spacc-dropdown" className="w-full z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-gray-700 w-full dark:text-gray-200" aria-labelledby="sp-t">
                        {/* <li  className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-tacc').click();
                                e.preventDefault();
                                setType({ key: 'tvl', label: 'Total Value Locked' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Total Value Locked</a>
                        </li> */}
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-tacc').click();
                                e.preventDefault();
                                setType({ key: 'volume', label: 'Total Volume' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Total Volume</a>
                        </li>
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-tacc').click();
                                e.preventDefault();
                                setType({ key: 'fees', label: 'Total Fees' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Total Fees</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='titles-actions flex justify-between'>
                <div className='mb-4'>
                    <h1 style={{ color: '#999' }} className='font-normal text-sm'>{ type.label } (in USD)</h1>
                    <h2 className='text-lg font-bold-md'>${ commarize(tx.data.accumelated?.value || 0) }</h2>
                </div>
                <div className='actions'>
                    {/* <button>A</button> */}
                </div>
            </div>
            <div className='body'>
                <div className='chart mb-2'>
                    <VLineChart 
                        options={SimpleLineOptions({ dollar: '$' }).options}
                        datasetOptions={SimpleLineOptions({ dollar: '$' }).datasetOptions}
                        rootClassName={'h-[200px]'}
                        labels={tx.data.data.map((x) => x.datetime)} 
                        records={tx.data.data.map((x) => x.value)} />
                </div>
            </div>
        </div>
    )
}
