import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { ifNanGiveZero } from "../helper";
import ExchangeService from "../services/ExchangeService";
import { DateRange } from "react-date-range";

export const ModalCalendar = (props) => {
    const ref = useRef(null);
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            color: '#999',
            key: 'selection'
        }
    ]);
    const { onClose } = props;

    const myClose = (e) => {

        if (ref.current) {
            ref.current.classList.remove('opacity-100');
        }
        setTimeout(() => {
            onClose && onClose(e)
        }, 300)
    }

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                // ref.current.classList.remove('opacity-0');
                ref.current.classList.add('opacity-100');
            }
        }, 0)
    }, [])

    return createPortal(
        <div ref={ref} id="modalCalendar" tabIndex="-1" aria-hidden="true" className="transition-all duration-300 opacity-0 fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            <div className="modal-content flex justify-center flex-col m-auto z-[901]">
                <div className="flex items-center justify-center mb-1 mt-4">
                    <DateRange
                        onChange={item => {
                            state[0].startDate = item.selection.startDate;
                            state[0].endDate = item.selection.endDate;
                            setState([...state])
                        }}
                        ranges={state}
                    />
                </div>

                <button onClick={() => {
                    myClose(state);
                }} className="btn-warning" style={{ padding: '1ex', background: '#fff', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)' }}>Done</button>

            </div>
        </div>
        , document.body)
}