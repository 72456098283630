import { useEffect } from 'react';
import { VBarChart } from '../Charts/VBarChart';
import StatsService from '../../services/StatsService';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStats } from '../../store/slices/statsSlice';
import Loader from '../Loader';
import { commarize } from '../../helper';

export default function UserStatsWidget(props) {
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const userStats = stats.usersCount;
    const filter = useSelector((x) => x.filter);

    useEffect(() => {
        dispatch(getUserStats());
    }, [filter.calendar?.range, filter.network, filter.refresh])

    return (
        <div className='user-stats'>
            <div className='widget-header mb-6 flex flex-row justify-between'>
                <div className='left'>
                    <h1 className='font-semibold text-xl'>User Stats</h1>
                    <p className='text-[#777980] text-sm'>Unique number of Wallets</p>
                </div>
                <div className='right'>
                    { userStats.loader && (
                        <Loader />
                    ) }
                    <div className='hidden range border rounded-lg border-slate-200 p-1 w-[180px] items-center flex justify-center'>
                        <button className='p-1 w-[50px] rounded bg bg-[#F4ECFB] text-[#883DCF] font-medium'>7d</button>
                        <button className='p-1 w-[50px] rounded font-normal'>1y</button>
                        <button className='p-1 w-[50px] rounded font-normal'>3y</button>
                        <button className='p-1 w-[50px] rounded font-normal'>All</button>
                    </div>
                </div>
            </div>
            <div className='stats mb-5 flex flex-row gap-4'>
                <div className='total-users-stats flex gap-2 text-[#777980] text-sm flex-row'>
                    <img src='/assets/imgs/user-badge.svg' />
                    <div className='numbers flex flex-col'>
                        <h4 className='text-xs'>Total Users</h4>
                        <div className='change flex flex-row gap-2 items-center'>
                            <h3 className='text-black font-semibold text-lg'>{ commarize(userStats?.data.totalUsers?.count, 0) }</h3>
                            {/* <div className='text-black font-medium flex flex-row items-center gap-1'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.27325 10.6667H11.7266C11.8584 10.6661 11.9872 10.6265 12.0965 10.5528C12.2058 10.4791 12.2908 10.3746 12.3408 10.2526C12.3907 10.1306 12.4034 9.99648 12.3771 9.86727C12.3508 9.73806 12.2869 9.61954 12.1932 9.52669L8.47325 5.8067C8.41127 5.74421 8.33754 5.69461 8.2563 5.66077C8.17506 5.62692 8.08792 5.6095 7.99991 5.6095C7.91191 5.6095 7.82477 5.62692 7.74353 5.66077C7.66229 5.69461 7.58856 5.74421 7.52658 5.8067L3.80658 9.52669C3.71297 9.61954 3.64898 9.73806 3.62273 9.86727C3.59647 9.99648 3.60912 10.1306 3.65907 10.2526C3.70902 10.3746 3.79403 10.4791 3.90335 10.5528C4.01267 10.6265 4.1414 10.6661 4.27325 10.6667Z" fill="#1A9882" />
                                </svg>
                                <span className='text-[#1A9882] font-semibold'>10%</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='hidden total-users-stats flex gap-2 text-[#777980] text-sm flex-row'>
                    <img src='/assets/imgs/track-badge.svg' />
                    <div className='numbers flex flex-col'>
                        <h4 className='text-xs'>Daily Users</h4>
                        <div className='change flex flex-row gap-2 items-center'>
                            <h3 className='text-black font-semibold text-lg'>3042</h3>
                            {/* <div className='text-black font-medium flex flex-row items-center gap-1'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.27325 10.6667H11.7266C11.8584 10.6661 11.9872 10.6265 12.0965 10.5528C12.2058 10.4791 12.2908 10.3746 12.3408 10.2526C12.3907 10.1306 12.4034 9.99648 12.3771 9.86727C12.3508 9.73806 12.2869 9.61954 12.1932 9.52669L8.47325 5.8067C8.41127 5.74421 8.33754 5.69461 8.2563 5.66077C8.17506 5.62692 8.08792 5.6095 7.99991 5.6095C7.91191 5.6095 7.82477 5.62692 7.74353 5.66077C7.66229 5.69461 7.58856 5.74421 7.52658 5.8067L3.80658 9.52669C3.71297 9.61954 3.64898 9.73806 3.62273 9.86727C3.59647 9.99648 3.60912 10.1306 3.65907 10.2526C3.70902 10.3746 3.79403 10.4791 3.90335 10.5528C4.01267 10.6265 4.1414 10.6661 4.27325 10.6667Z" fill="#1A9882" />
                                </svg>
                                <span className='text-[#1A9882] font-semibold'>3%</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='hidden total-users-stats flex gap-2 text-[#777980] text-sm flex-row'>
                    <img src='/assets/imgs/user-badge-2.svg' />
                    <div className='numbers flex flex-col'>
                        <h4 className='text-xs'>Monthly Users</h4>
                        <div className='change flex flex-row gap-2 items-center'>
                            <h3 className='text-black font-semibold text-lg'>4.94K</h3>
                            <div className='text-black font-medium flex flex-row items-center gap-1'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.27602 6H11.724C11.8559 6.00003 11.9847 6.03914 12.0943 6.1124C12.2039 6.18565 12.2894 6.28976 12.3398 6.41156C12.3903 6.53336 12.4035 6.66738 12.3777 6.79669C12.352 6.92599 12.2886 7.04476 12.1954 7.138L8.47135 10.862C8.34634 10.987 8.1768 11.0572 8.00002 11.0572C7.82325 11.0572 7.65371 10.987 7.52869 10.862L3.80469 7.138C3.71148 7.04476 3.64801 6.92599 3.6223 6.79669C3.59659 6.66738 3.60979 6.53336 3.66024 6.41156C3.71068 6.28976 3.79611 6.18565 3.90572 6.1124C4.01532 6.03914 4.14419 6.00003 4.27602 6Z" fill="#EB3D4D" />
                                </svg>
                                <span className='text-[#EB3D4D] font-semibold'>2%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VBarChart labels={userStats.data.usersCounts.map((x) => x.period)} records={userStats.data.usersCounts.map((x) => x.count)} />
        </div>
    )
}
