import { useEffect, useMemo, useState } from 'react';
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { getMostUsedFeatureSplit, getPopularDevices } from '../../store/slices/statsSlice';
import { VLineChart } from '../Charts/VLineChart'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { AppConstants } from '../../constants';
import sum from 'lodash/sum';

export default function PopularDevices(props) {
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    const popularDevices = stats.popularDevices;

    useEffect(() => {
        dispatch(getPopularDevices());
    }, [filter.calendar?.range, filter.network, filter.refresh])

    const total = sum(Object.values(popularDevices.data.data || {})) || 0;
    const mobilePercent = useMemo(() => ((popularDevices.data?.data?.mobile || 0) / total) * 100); 
    const webPercent = useMemo(() => ((popularDevices.data?.data?.web || 0) / total) * 100); 

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-6 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Devices</h1>
                    <p className='text-gray text-sm'>Number of transactions across different devices</p>
                </div>
                <div className="right flex flex-row justify-between">
                    { popularDevices.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    ) }
                    <ElipseIcon />
                </div>
            </div>

                <div className='actions mb-6'>
                    <div className='bars flex justify-center rounder-full overflow-hidden items-center gap-0'>
                        {(!popularDevices.loader && total > 0) && (
                            <>
                                <div
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content="Desktop" 
                                    style={{ width: webPercent + '100%' }} className='bar rounded-bl-[10px] rounded-tl-[10px] bg-[#0d42f9] h-[15px]'></div>
                                <div
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content="Mobile" 
                                    style={{ width: mobilePercent + '100%' }} className='bar rounded-br-[10px] rounded-tr-[10px] bg-[#f48e3b] h-[15px] w-[40%]'></div>
                            </>
                        )}
                    </div>
                </div>

            <div className='body'>
                <div className='items flex flex-col'>
                    <div className='item flex mb-2 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <span className='text-sm'>Feature</span>
                        </div>
                        <div className='right'>
                            <span className='text-sm'>Transactions</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] w-[40px] h-[40px]'>
                                <img src='/assets/imgs/desktop.svg' />
                            </div>
                            <div className='flex flex-col'>
                                <span>Desktop</span>
                                {/* <span className='text-sm'>hiro.so</span> */}
                            </div>
                        </div>
                        <div className='right'>
                            <span>{ popularDevices.data?.data?.web || 0 }</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] w-[40px] h-[40px]'>
                                <img src='/assets/imgs/mobile.svg' />
                            </div>
                            <div className='flex flex-col'>
                                <span>Mobile</span>
                                {/* <span className='text-sm'>xverse.so</span> */}
                            </div>
                        </div>
                        <div className='right'>
                            <span>{ popularDevices.data?.data?.mobile || 0 }</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
