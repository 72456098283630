import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PoolService from '../../services/PoolService';

const InitialState = {
    poolsLoader: false,
    pools: { statusCode: '', message: [] },
};

export const getPools = createAsyncThunk(
    'pools/list',
    async (name, store) => {
        const pools = await PoolService.getPools();
        return pools;
    }
)

export const poolSlice = createSlice({
    name: 'pool',
    initialState: InitialState,
    reducers: {
        setPoolLoader (state, action) {
            state.poolsLoader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPools.fulfilled, (state, action) => {
            state.poolsLoader = false;
            state.pools = action.payload;
        })
        builder.addCase(getPools.rejected, (state, action) => {
            state.poolsLoader = false;
            state.pools = InitialState.pools;
        })
    },
})

export const { setPoolLoader } = poolSlice.actions
export default poolSlice.reducer
