import { commarize } from "../../helper";

export const LineDefaultOptions = ({ dollar }) => ({
  datasetOptions: {
    lineTension: 0.1,
    borderColor: "#883DCF",
    borderCapStyle: "butt",
    borderDash: [],
    pointBorderColor: "#883DCF",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    borderWidth: 3,
    pointHoverBackgroundColor: "rgba(131,138,133,1)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 10,
    backgroundColor: ['#f89d33'],
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: false, // Hide legend
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          usePointStyle: true,
          beforeBody: function (context) {
            if (dollar) {
              return dollar + commarize(context[0].raw);
            } else {
              return context[0].raw;
            }
          },
          label : function (c) {
            return '';
          },
          labelColor: function(context) {
              return {
                  borderColor: 'rgb(0, 0, 255)',
                  backgroundColor: '#aaa',
                  borderWidth: 0,
                  borderDash: [2, 2],
                  borderRadius: 0,
              };
          },
          labelTextColor: function(context) {
              return '#aaa';
          }
        }
      }
    },
    scales: {
      x: {
        display: false,
        border: {
          display: false
        },
        ticks: {
          display: false
        },
      },
      y: {
          display: true,
          gridLines: {
              display: false
          },
          ticks: {
            display: true
          },
      },
    }
  }
})


export const SimpleLineOptions = ({ dollar }) => ({
  datasetOptions: {
    lineTension: 0.1,
    borderColor: "#883DCF",
    borderCapStyle: "butt",
    borderDash: [],
    pointBorderColor: "#883DCF",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    borderWidth: 3,
    pointHoverBackgroundColor: "rgba(131,138,133,1)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 3,
    pointHitRadius: 10,
    backgroundColor: ['#f89d33'],
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: false, // Hide legend
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          usePointStyle: true,
          beforeBody: function (context) {
            if (dollar) {
              return dollar + commarize(context[0].raw);
            } else {
              return context[0].raw;
            }
          },
          label : function (c) {
            return '';
          },
          labelColor: function(context) {
              return {
                  borderColor: 'rgb(0, 0, 255)',
                  backgroundColor: '#aaa',
                  borderWidth: 0,
                  borderDash: [2, 2],
                  borderRadius: 0,
              };
          },
          labelTextColor: function(context) {
              return '#aaa';
          }
        }
      }
    },
    scales: {
      x: {
        display: false,
        border: {
          display: false
        },
        ticks: {
          display: false
        },
      },
      y: {
          display: false,
          gridLines: {
              display: false
          },
          ticks: {
              display: false
          },
      },
    }
  }
})