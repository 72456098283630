export const AppConstants = {
    popularWallets: {
        xverse: { label: 'Xverse Wallet', subLabel: 'xverse.so', color: '#883DCF', icon: '/assets/icons/swap.svg' },
        hiro: { label: 'Leather Wallet', subLabel: 'leather.io', color: '#883DCF', icon: '/assets/icons/swap.svg' } 
    },
    popularDevices: {
        web: { label: 'Web', color: '#883DCF', icon: '/assets/icons/swap.svg' },
        mobile: { label: 'Mobile', color: '#883DCF', icon: '/assets/icons/swap.svg' } 
    },
    module: {
        swap: { label: 'Swap', color: '#883DCF', icon: '/assets/icons/swap.svg', mainnet: true },
        pool: { label: 'Pool', color: '#F9C80E', icon: '/assets/icons/rewards.svg', mainnet: true },
        farm: { label: 'Farm', color: '#F86624', icon: '/assets/icons/swap.svg', mainnet: false },
        stake: { label: 'Stake', color: '#2BB2FE', icon: '/assets/icons/swap.svg', mainnet: false },
        faucet: { label: 'Faucet', color: 'orange', icon: '/assets/icons/swap.svg', mainnet: false },
    },
    App: {
        URL: 'https://dev--iridescent-melba-80337e.netlify.app/',
        Name: 'Velar',
        Icon: window.location.origin + '/assets/imgs/velar-logo.png'
    },
    Wallets: {
        Hiro: 'hiro',
        XVerse: 'xverse'
    },
    Hiro :{
        browserExtensionLink:'https://chrome.google.com/webstore/detail/hiro-wallet/ldinpeekobnhjjdofggfgjlcehhmanlj'
    },
    XVerse :{
        browserExtensionLink:'https://chrome.google.com/webstore/detail/xverse-wallet/idnnbdplmphpflfnlkomgpfbpcgelopg'
    },
    Networks: {
        TESTNET: 'testnet',
        MAINNET: 'mainnet',
        DEVNET: 'devnet'
    },
    PostConditionType: {
        ContractFungible: 'contract-fungible',
        StandardSTX: 'standard',
        StandardSTXFungible: 'standard-fungible',
        ContractSTX: 'contract-stx',
    },
    NATIVE_TOKEN_SYMBOL: 'wstx',
    WalletConnect: {
        CHAINS: [
            'stacks:1',
            'stacks:2147483648',
        ]
    },
    SWAP_FUNCTIONS: [
        'swap-tokens-for-exact-tokens',
        'swap-exact-tokens-for-tokens'
    ],
    STAKE_FUNCTIONS: [
        'stake',
        'unstake'
    ],
    STAKE_REWARDS: [
        'distribute-epochs'
    ],
    POOL_FUNCTIONS: [
        'add-liquidity',
        'remove-liquidity'
    ],
    Views: {
        GRID: 'GRID',
        LIST: 'LIST'
    },
    LOCAL_STORAGE_KEYS: {
        App: 'app',
        splash: 'splash',
        Calendar: 'calendar',
        Network: 'network'
    }
}