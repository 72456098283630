import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { VDoughnutChart } from '../Charts/VDoughnutChart'
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionSplits, getUserStats } from '../../store/slices/statsSlice';
import Loader from '../Loader';
import { commarize } from '../../helper';
import { useEffect, useState } from 'react';
import { AppConstants } from '../../constants';

export default function TransactionSplitWidget(props) {
    const [ status, setStatus ] = useState({ label: 'Successful Transactions', key: 'success' })
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    const splits = stats.transactionsSplits;

    useEffect(() => {
        dispatch(getTransactionSplits({ status: status.key }));
    }, [status.key, filter.calendar?.range, filter.network, filter.refresh])

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-4 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Transaction Split</h1>
                    <p className='text-gray text-sm'>Number of transactions across different features</p>
                </div>
                <div className="right flex flex-row justify-between">
                        { splits.loader && (
                            <div>
                                <Loader style={{ marginRight: 15 }} />
                            </div>
                        ) }
                    <ElipseIcon />
                </div>
            </div>
            <div className='actions mb-4'>
                <button id="sp-t" data-dropdown-toggle="sp-dropdown" className="w-full justify-between border-slate-200 text-white bg-white border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                    <div className='flex flex-row gap-2 items-center'>
                        <TransactionBagIcon />
                        <span style={{ color: '#555' }}>{ status.label }</span>
                    </div>
                    <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <div id="sp-dropdown" className="w-full z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-gray-700 w-full dark:text-gray-200" aria-labelledby="sp-t">
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-t').click();
                                e.preventDefault();
                                setStatus({ key: 'success', label: 'Successful Transaction' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Successful Transaction</a>
                        </li>
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-t').click();
                                e.preventDefault();
                                setStatus({ key: 'failed', label: 'Failed Transaction' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Failed Transaction</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body'>
                <div className='chart mb-2'>
                    <VDoughnutChart 
                        change={splits.data.change}
                        total={splits.data.total}
                        values={splits.data.series.map((x) => { 
                            const module = AppConstants.module[x.module] || { label: 'Unknown', color: '#000' };
                            return {
                                label: module.label,
                                color: module.color,
                                value: x.counts
                            } 
                        })} 
                    />
                </div>
                <div className='items flex flex-col px-4'>
                    <div className='item flex mb-2 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <span className='text-sm'>Feature</span>
                        </div>
                        <div className='right'>
                            <span className='text-sm'>Transactions</span>
                        </div>
                    </div>
                    {Object.keys(AppConstants.module).filter((x) => x !== 'faucet').map((module, index) => {
                        const counts = splits.data.series.find((x) => x.module === module)?.counts || 0;
                        return (
                            <div key={index} className='item flex mb-3 flex-row justify-between gap-2'>
                                <div className='left flex flex-row justify-between gap-2 items-center'>
                                    <div className={`circle rounded-full w-3 h-3`} style={{ backgroundColor: AppConstants.module[module].color }}>
                                        {/* <img src="/assets/icons/swap.svg" /> */}
                                    </div>
                                    <span>{ AppConstants.module[module].label }</span>
                                </div>
                                <div className='right'>
                                    { filter.network === AppConstants.Networks.TESTNET ? (
                                        <span>{ counts }</span>
                                    ) : (
                                        <span
                                            data-tooltip-id="tooltip" 
                                            data-tooltip-content={AppConstants.module[module].mainnet ? counts : 'To be announced'} 
                                            >{ AppConstants.module[module].mainnet ? counts : 'TBA' }</span>
                                    ) }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
