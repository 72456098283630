import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoutes = () => {
    const auth = useSelector((x) => x.auth);
    const app = useSelector((x) => x.app);
    const navigate = useNavigate();


    if (!auth.isLoggedIn) {
        window.location = '/login';
        return '';
    }

    if (app.splash && !auth.isLoggedIn) {
        return '';
    }



    return(
        auth.isLoggedIn ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes;