import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../contexts/LayoutContext';
import { useDispatch } from 'react-redux';
import { authHttp } from '../utils/axiosProvider';
import { updateAuth } from "../store/slices/authSlice";
import { useNavigate } from 'react-router-dom';
import { Link, NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import Loader from '../components/Loader';

export const Register = (props) => {
    const dispatch = useDispatch();
    const [ state, setState ] = useState({ code: '',  email: '', password: '', confirm_password: '', name: '' });
    const [ errorState, setErrorState ] = useState({ code: '',  email: '', password: '', confirm_password: '', name: '' });
    const [ globalErrorState, setGlobalErrorState ] = useState('');
    const [ loader, setLoader ] = useState(false);
    const navigate = useNavigate();

    const onChange = (val, name) => {
        setState({ ...state, [name]: val })
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email)) {
            setErrorState({ email: 'Please enter valid email address.' });
            return;
        }

        if (state.password !== state.confirm_password) {
            setGlobalErrorState('Confirm password do not match');
            return;
        }

        if (!state.email || !state.password) {
            setErrorState({ email: 'Username is required', password: 'Password is required.' });
            return;
        }
        setLoader(true);
        try {
            await authHttp.post('/auth/adminsignup', { email: state.email, password: state.password, code: state.code, confirm_password: state.confirm_password, name: state.name });
            navigate('/login');
            setLoader(false);
            toast('Registerd successfully please sigin', { type: 'success', position: 'top-right' });
            // window.location = "/login";
        } catch (error) {
            setLoader(false);
            if (error?.response?.status === 400) {
                setGlobalErrorState(error.response.data.message);
                return;
            } 

            setGlobalErrorState('Email or password is not correct');
        }
    }

    return (
        <div className='login'>
            <section className="bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col items-center px-6 py-2 mt-3 mx-auto md:h-screen lg:py-0">
                    <a href="#" className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="mr-2" src="/assets/imgs/login-logo.svg" alt="logo" />
                    </a>
                    <div className="w-full rounded-lg dark:border sm:max-w-md dark:bg-gray-800 dark:border-gray-700">
                        <div className='text-center mb-1 mt-1'>
                            <h1 className="text-xl mb-1 font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Register
                            </h1>
                            <p className='font-weight-200 text-gray-200'>Welcome back! Please enter your details.</p>
                        </div>
                        <div className="p-6 space-y-4 md:space-y-2 sm:p-8">
                            <form onSubmit={submit} className="space-y-4 md:space-y-6" action="#">
                                 <div>
                                    <label for="name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                    <input type="text" required name="name" id="name" value={state.name} onChange={(e) => onChange(e.target.value, 'name')} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="Enter your email" />
                                    <p className='text-sm mt-1 text-red-600'>{ errorState.name }</p>
                                </div>
                                <div>
                                    <label for="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input type="email" required name="email" id="email" value={state.email} onChange={(e) => onChange(e.target.value, 'email')} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="Enter your email" />
                                    <p className='text-sm mt-1 text-red-600'>{ errorState.email }</p>
                                </div>

                                <div>
                                    <label for="password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input type="password" required name="password" id="password" value={state.password} onChange={(e) => onChange(e.target.value, 'password')} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" />
                                    <p  className='text-sm mt-1 text-red-600'>{ errorState.password }</p>
                                </div>

                                <div>
                                    <label for="confirm_password" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                    <input type="password" required name="password" id="confirm_password" value={state.confirm_password} onChange={(e) => onChange(e.target.value, 'confirm_password')} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" />
                                    <p  className='text-sm mt-1 text-red-600'>{ errorState.confirm_password }</p>
                                </div>

                                <div>
                                    <label for="code" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Activation Code</label>
                                    <input type="code" required name="text" id="code" value={state.code} onChange={(e) => onChange(e.target.value, 'code')} placeholder="Enter Activation Code" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-600 focus:border-gray-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" />
                                    <p  className='text-sm mt-1 text-red-600'>{ errorState.code }</p>
                                </div>


                                { globalErrorState && (
                                    <p className='text-sm mt-0 rounded text-center bg-red-100 p-3 text-red-600'>{ globalErrorState }</p>
                                ) }

                                <button type="submit" className="w-full bg-[#F89D33] text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">Register</button>

                                { loader && (
                                    <Loader />
                                ) }
                                <p className='text-center'>Already have account? <NavLink to={'/login'} className='text-[#CC812A]'>Login</NavLink></p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>  
        </div>
    )
}