import './doughnut-textinside';
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from 'chart.js';
import React from "react";
import { Doughnut, Chart } from "react-chartjs-2";
ChartJs.register(ArcElement, Tooltip, Legend);


export const VDoughnutChart = (props) => {
    const values = props.values || [];
    const total = props.total || 0;
    const change = props.change || 0;

    const options = {
        maintainAspectRatio: false,
        plugins: {
          legend: false, 
        },
        cutout: 108,
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                gridLines: {
                    display:false
                }   
            }]
        }
      };

    const data = {
        labels: values.map((x) => x.label),
        datasets: [
            {
                label: '# of Transactions',
                total: total,
                change: change,
                data: values.map((x) => x.value),
                backgroundColor: values.map((x) => x.color),
                borderColor: values.map((x) => x.color),
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className='h-[250px]'>
            <Chart type='doughnut-textinside' data={data} options={options} />
        </div>
    );
}