import { AppConstants } from "./constants";

export const ROOT_URL = '/';
const env = process.env.REACT_APP_NETWORK;
const CONTRACT_BASE_ADDRESS = process.env.REACT_APP_CONTRACT_BASE_ADDRESS;
const VELAR_WALLET_ADDRESS = process.env.REACT_APP_VELAR_WALLET_ADDRESS;
const SWAP_API_URL = process.env.REACT_APP_SWAP_API_URL;
const POOL_API_URL = process.env.REACT_APP_POOL_API_URL;
const STAKE_API_URL = process.env.REACT_APP_STAKE_API_URL;
const FAUCET_API_URL = process.env.REACT_APP_FAUCET_API_URL;
const BLOCKCHAIN_API_URL = process.env.REACT_APP_BLOCKCHAIN_API_URL;
const BLOCKCHAIN_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER;
const APP_AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

const APP_INSIGHT_API_URL = process.env.REACT_APP_APP_INSIGHT_API_URL;
const APP_TESTNET_INSIGHT_API_URL = process.env.REACT_APP_TESTNET_INSIGHT_API_URL;

export const Config = {
    velarAddress: VELAR_WALLET_ADDRESS,
    insightApi: APP_INSIGHT_API_URL || 'https://velarinsights-7e87085aadda.herokuapp.com/',
    testnetInsightApi: APP_TESTNET_INSIGHT_API_URL || 'https://velarinsights-testnet-dev-749ca8e81dbd.herokuapp.com/',

    swapApi: SWAP_API_URL, //'https://test-swap-app.velar.co',
    poolApi: POOL_API_URL, //'https://test-pool-app.velar.co',
    stackApi: STAKE_API_URL, //'https://test-staking-app.velar.co',
    faucetApi: FAUCET_API_URL, //'https://test-faucet-app.velar.co',
    authApi: APP_AUTH_API_URL,
    ContractAddresses: {
        FarmSend: CONTRACT_BASE_ADDRESS + '.send',
        Staking: CONTRACT_BASE_ADDRESS + '.staking-core',
        FarmStaking: CONTRACT_BASE_ADDRESS + '.wstx-velar-farming-core',
        Library: CONTRACT_BASE_ADDRESS + '.library-v2',
        Core: CONTRACT_BASE_ADDRESS + '.core',
        Distributor: CONTRACT_BASE_ADDRESS + '.staking-distributor',
        FarmDistributor: CONTRACT_BASE_ADDRESS + '.wstx-velar-farming-distributor',
        StakingDistributor: CONTRACT_BASE_ADDRESS + '.staking-distributor',
        Router: CONTRACT_BASE_ADDRESS + '.router-v2',
        Create: CONTRACT_BASE_ADDRESS + '.create',
        Velar: CONTRACT_BASE_ADDRESS + '.velar',
        WSTX: CONTRACT_BASE_ADDRESS + '.wstx',
        Pool: {
            WSTX_VELAR: CONTRACT_BASE_ADDRESS + '.wstx-velar'
        }
    },
    apiURL: BLOCKCHAIN_API_URL,
    name: env,
    url: BLOCKCHAIN_EXPLORER
}; 

export const BASE_ADDRESS = CONTRACT_BASE_ADDRESS;