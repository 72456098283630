import { useEffect, useMemo, useState } from 'react';
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { getMostUsedFeatureSplit } from '../../store/slices/statsSlice';
import { VLineChart } from '../Charts/VLineChart'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { AppConstants } from '../../constants';
import sum from 'lodash/sum';

const features = Object.keys(AppConstants.module);

export default function MostUsedFeaturedWidget(props) {
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    const mostUsedFeatures = stats.mostUsedFeatures;

    useEffect(() => {
        dispatch(getMostUsedFeatureSplit({ status: 'success' }));
    }, [filter.calendar?.range, filter.network, filter.refresh])

    const total = useMemo(() => sum(mostUsedFeatures.data.series.map((x) => x.counts)) || 0);

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-6 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Features</h1>
                    <p className='text-gray text-sm'>Unique Wallets interacting with Features</p>
                </div>
                <div className="right flex flex-row justify-between">
                    { mostUsedFeatures.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    ) }
                    <ElipseIcon />
                </div>
            </div>

            <div className='actions mb-6'>
                <div className='bars flex justify-center rounder-full overflow-hidden items-center gap-0'>
                    {mostUsedFeatures.data.series.filter((x) => {
                        if (x === 'faucet' && filter.network === AppConstants.Networks.MAINNET) {
                            return false;
                        } else {
                            return x;
                        }
                    }).filter(Boolean).map((module, index) => {
                        const percent = (module.counts / total) * 100;
                        if (index === 0) {
                            return (
                                <div 
                                data-tooltip-id="tooltip" 
                                data-tooltip-content={AppConstants.module[module.module]?.label + ': ' + module.counts} 
                                key={index} style={{ width: percent + '%', backgroundColor: AppConstants.module[module.module]?.color }} className='bar rounded-bl-[10px] rounded-tl-[10px] w-[30%] h-[15px]'></div>
                            )
                        } else if ((mostUsedFeatures.data.series.length - 1) === index) {
                            return (
                                <div
                                data-tooltip-id="tooltip" 
                                data-tooltip-content={AppConstants.module[module.module]?.label + ': ' + module.counts} 
                                key={index} style={{ width: percent + '%', backgroundColor: AppConstants.module[module.module]?.color }} className='bar rounded-br-[10px] rounded-tr-[10px] h-[15px] w-[20%]'></div>
                            )
                        } else {
                            return (
                                <div
                                data-tooltip-id="tooltip" 
                                data-tooltip-content={AppConstants.module[module.module]?.label + ': ' + module.counts} 
                                key={index} style={{ width: percent + '%', backgroundColor: AppConstants.module[module.module]?.color }} className='bar h-[15px] bg-[#DB00FF] w-[20%]'></div>
                            )
                        }
                    })}
                    {/* <div className='bar rounded-bl-[10px] rounded-tl-[10px] bg-[#2BB2FE] w-[30%] h-[15px]'></div>
                    <div className='bar h-[15px] bg-[#DB00FF] w-[10%]'></div>
                    <div className='bar bg-[#F9C80E] h-[15px] w-[20%]'></div>
                    <div className='bar bg-[#FF5500] h-[15px] w-[20%]'></div> */}
                </div>
            </div>

            <div className='body'>
                <div className='items flex flex-col'>
                    <div className='item flex mb-2 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <span className='text-sm'>Features</span>
                        </div>
                        <div className='right'>
                            <span className='text-sm'>Unique Wallets</span>
                        </div>
                    </div>
                    {Object.keys(AppConstants.module).filter((x) => {
                        if (x === 'faucet' && filter.network === AppConstants.Networks.MAINNET) {

                        } else {
                            return x;
                        }
                    }).map((module) => {
                        const counts = mostUsedFeatures.data.series.find((x) => x.module === module)?.counts || 0;
                        console.log(counts);
                        return (
                            <div key={module} className='item flex mb-4 flex-row items-center justify-between gap-2'>
                                <div className='left flex flex-row justify-between gap-2 items-center'>
                                    <div className='box rounded-[8px] items-center flex justify-center w-[40px] h-[40px]' style={{ backgroundColor: AppConstants.module[module]?.color }}>
                                        <img src="/assets/icons/swap.svg" style={{ width: 18 }} />
                                    </div>
                                    <span>{ AppConstants.module[module]?.label }</span>
                                </div>
                                <div className='right'>
                                { filter.network === AppConstants.Networks.TESTNET ? (
                                        <span>{ counts }</span>
                                    ) : (
                                        <span
                                            data-tooltip-id="tooltip" 
                                            data-tooltip-content={AppConstants.module[module].mainnet ? counts : 'To be announced'} 
                                            >{ AppConstants.module[module].mainnet ? counts : 'TBA' }</span>
                                    ) }
                                </div>
                            </div>
                        )
                    })}
                    {/* <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box items-center flex justify-center rounded-[8px] bg-[#DB00FF] w-[40px] h-[40px]'>
                                <img src="/assets/icons/rewards.svg" style={{ width: 20, color: '#fff' }} />
                            </div>
                            <span>Pool</span>
                        </div>
                        <div className='right'>
                            <span>$10,0000</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] items-center flex justify-center bg-[#F9C80E] w-[40px] h-[40px]'>
                                <img src="/assets/icons/rewards.svg" style={{ width: 20, color: '#fff' }} />
                            </div>
                            <span>Farm</span>
                        </div>
                        <div className='right'>
                            <span>$10,0000</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] items-center flex justify-center bg-[#FF5500] w-[40px] h-[40px]'>
                                <img src="/assets/icons/rewards.svg" style={{ width: 20, color: '#fff' }} />
                            </div>
                            <span>Stake</span>
                        </div>
                        <div className='right'>
                            <span>$10,0000</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] items-center flex justify-center bg-[#0E42F9] w-[40px] h-[40px]'>
                                <img src="/assets/icons/rewards.svg" style={{ width: 20, color: '#fff' }} />
                            </div>
                            <span>Faucet</span>
                        </div>
                        <div className='right'>
                            <span>$10,0000</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
