import http from '../utils/axiosProvider';
import VelarService from './VelarService';
import { openContractCall } from '@stacks/connect';
import { AnchorMode, FungibleConditionCode, PostConditionMode, createAssetInfo, createSTXPostCondition, estimateContractFunctionCall, makeContractFungiblePostCondition, makeStandardSTXPostCondition } from '@stacks/transactions';
import BigNum from 'bn.js';
import { stringify, toJSON } from '../helper';
import { AppConstants } from '../constants';
import SwapService from './SwapService';
import PoolService from './PoolService';
import StakeService from './StakeService';
import { getTransactions, setTransactionIntervalLoader, setTransactionLoader } from "../store/slices/chainSlice";

export class ContractService { 
    constructor (contractAddress, contractName) {
        this.contractAddress = contractAddress;
        this.contractName = contractName;
    }

    async callReadOnly (fn, payload) {
       return (await http.post(`/v2/contracts/call-read/${this.contractAddress}/${this.contractName}/${fn}`, payload))
    }

    async callPublicFunction (functionName, functionArgs = [], postConditions = [], transactionApiPayload = {}, mode = PostConditionMode.Deny) {
        const dispatch = window.velarStore?.dispatch;
        return new Promise (async (resolve, reject) => {
            const options = {
                contractAddress: this.contractAddress,
                contractName: this.contractName,
                functionName: functionName,
                functionArgs: functionArgs,
                network: VelarService.getNetwork(),
                appDetails: VelarService.getAppDetails(),
                postConditions: postConditions,
                postConditionMode: mode,
                anchorMode: AnchorMode.Any,
                onFinish: (data) => {
                    const txJSON = toJSON(stringify(data));
                    console.log(txJSON);
                    
                    try {
                        const localData = JSON.parse(localStorage.getItem('wallet-state'));
                        transactionApiPayload.walletInUse = localData.wallet;
                    } catch (error) {}

                    transactionApiPayload.fee = 0;
                    transactionApiPayload.txId = txJSON.txId;
                    transactionApiPayload.status = '';
                    transactionApiPayload.exchangeRate = '';
                    transactionApiPayload.txResponse = txJSON;
                    transactionApiPayload.transactionHash = txJSON.txId
                    this.saveTransaction(functionName, transactionApiPayload).then((res) => {});

                    setTimeout(() => {
                        if (dispatch) {
                            dispatch(setTransactionIntervalLoader(true));
                            dispatch(getTransactions());
                        }
                    }, 3000);

                    resolve(data);
                },
                onCancel: () => {
                    reject();
                },
            };
            await openContractCall(options);
        });
    }

    async saveTransaction (functionName, transactionApiPayload) {
        if (AppConstants.SWAP_FUNCTIONS.includes(functionName)) {
            await SwapService.saveTransaction(transactionApiPayload);
        } else if (AppConstants.POOL_FUNCTIONS.includes(functionName)) {
            await PoolService.saveTransaction(transactionApiPayload);
        } else if (AppConstants.STAKE_FUNCTIONS.includes(functionName)) {
            await StakeService.saveTransaction(transactionApiPayload);
        }
    }
}