export default function TransactionBagIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2927_4925)">
                <path d="M2 14.6667C2.00106 15.5504 2.35259 16.3976 2.97748 17.0225C3.60237 17.6474 4.4496 17.999 5.33333 18H14.6667C15.5504 17.999 16.3976 17.6474 17.0225 17.0225C17.6474 16.3976 17.9989 15.5504 18 14.6667V8.66669H2V14.6667ZM13.3333 11.6667C13.5311 11.6667 13.7245 11.7253 13.8889 11.8352C14.0534 11.9451 14.1815 12.1013 14.2572 12.284C14.3329 12.4667 14.3527 12.6678 14.3141 12.8618C14.2755 13.0558 14.1803 13.2339 14.0404 13.3738C13.9006 13.5136 13.7224 13.6089 13.5284 13.6475C13.3344 13.6861 13.1334 13.6663 12.9507 13.5906C12.7679 13.5149 12.6117 13.3867 12.5019 13.2223C12.392 13.0578 12.3333 12.8645 12.3333 12.6667C12.3333 12.4015 12.4387 12.1471 12.6262 11.9596C12.8138 11.772 13.0681 11.6667 13.3333 11.6667ZM10 11.6667C10.1978 11.6667 10.3911 11.7253 10.5556 11.8352C10.72 11.9451 10.8482 12.1013 10.9239 12.284C10.9996 12.4667 11.0194 12.6678 10.9808 12.8618C10.9422 13.0558 10.847 13.2339 10.7071 13.3738C10.5673 13.5136 10.3891 13.6089 10.1951 13.6475C10.0011 13.6861 9.80004 13.6663 9.61732 13.5906C9.43459 13.5149 9.27841 13.3867 9.16853 13.2223C9.05865 13.0578 9 12.8645 9 12.6667C9 12.4015 9.10536 12.1471 9.29289 11.9596C9.48043 11.772 9.73478 11.6667 10 11.6667ZM6.66667 11.6667C6.86445 11.6667 7.05779 11.7253 7.22224 11.8352C7.38669 11.9451 7.51486 12.1013 7.59055 12.284C7.66623 12.4667 7.68604 12.6678 7.64745 12.8618C7.60887 13.0558 7.51363 13.2339 7.37377 13.3738C7.23392 13.5136 7.05574 13.6089 6.86176 13.6475C6.66778 13.6861 6.46671 13.6663 6.28398 13.5906C6.10126 13.5149 5.94508 13.3867 5.8352 13.2223C5.72532 13.0578 5.66667 12.8645 5.66667 12.6667C5.66667 12.4015 5.77202 12.1471 5.95956 11.9596C6.1471 11.772 6.40145 11.6667 6.66667 11.6667Z" fill="#858D9D" />
                <path d="M14.6667 3.33333H14V2.66667C14 2.48986 13.9298 2.32029 13.8047 2.19526C13.6797 2.07024 13.5101 2 13.3333 2C13.1565 2 12.987 2.07024 12.8619 2.19526C12.7369 2.32029 12.6667 2.48986 12.6667 2.66667V3.33333H7.33333V2.66667C7.33333 2.48986 7.2631 2.32029 7.13807 2.19526C7.01305 2.07024 6.84348 2 6.66667 2C6.48986 2 6.32029 2.07024 6.19526 2.19526C6.07024 2.32029 6 2.48986 6 2.66667V3.33333H5.33333C4.4496 3.33439 3.60237 3.68592 2.97748 4.31081C2.35259 4.93571 2.00106 5.78294 2 6.66667L2 7.33333H18V6.66667C17.9989 5.78294 17.6474 4.93571 17.0225 4.31081C16.3976 3.68592 15.5504 3.33439 14.6667 3.33333Z" fill="#858D9D" />
            </g>
            <defs>
                <clipPath id="clip0_2927_4925">
                    <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>
    )
}
