import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { LayoutContext } from "../contexts/LayoutContext";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions, setTransactionLoader } from "../store/slices/chainSlice";
import Loader from "../components/Loader";
import { getFirstAndLast } from "../helper";
import { isBaseAddress, renderLiquidityPollTransaction, renderNotifications, renderSwapTransaction } from "../utils/notification.helper";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";



function Notifications(props) {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const ref = useRef(null);
    const btnRef = useRef(null);

    const chain = useSelector((x) => x.chain);
    const dispatch = useDispatch();
    useOutsideAlerter([ref, btnRef], layoutState, () => {
        setLayoutState({ ...layoutState, showNotifications: false })
    });

    const transactions = chain.transaction.data.result?.filter((x) => {
        if (isBaseAddress(x?.contract_call?.contract_id)) {
            return x;
        }
    })?.filter(Boolean) || [];

    const transactionLoader = chain.transaction.loader;

    useEffect(() => {
        dispatch(setTransactionLoader(true));
        dispatch(getTransactions());
    }, [])

    const renderNotify = () => {
        return (transactions || []).filter((x) => {
            if (isBaseAddress(x?.contract_call?.contract_id) && x.memepool === true) {
                return x;
            }
            // x.memepool === true
        }).length > 0 && (
            <span className="notify"></span>
        )
    }

    return (
        <>
            <div className="relative inline-flex w-fit">
                { renderNotify() }
                <button
                    ref={btnRef}
                    onClick={() => {
                        setLayoutState({ ...layoutState, showNotifications: !layoutState.showNotifications, isNotificationViewed: true })
                    }}
                    className="btn-secondary bg-transparent"
                    id="dropdownNotificationButton"
                    data-dropdown-placement="bottom">
                    <img
                        className="h-5 w-auto lg:block"
                        src="/assets/icons/bell.svg"
                        alt="Your Company"
                    />
                </button>
            </div>
            <div style={{ zIndex: 10, maxHeight: 620, overflowY: 'auto' }} ref={ref} className={layoutState.showNotifications ? "notification-dropdown z-6" : "notification-dropdown z-6 hidden"}
                aria-labelledby="dropdownNotificationButton">
                <h4 className="heading">Notifications</h4>
                <div className="list">
                    { (transactionLoader) && (
                        <div className="items-center justify-center text-center mt-5">
                            <Loader width={32} />
                        </div>
                    )}
                    { (transactions || []).filter((x) => {
                        if (isBaseAddress(x?.contract_call?.contract_id)) {
                            return x;
                        }
                    }).filter(Boolean).slice(0, 3).map((transaction, i) => {
                       return renderNotifications(transaction, i, ref);
                    }) }
                </div>
                { (!transactionLoader && (transactions || []).length > 0) && (
                    <Link to="/notifications" onClick={() => {
                        ref.current.classList.add('hidden')
                    }} href="javascript:void(0)" disabled={true} className="btn-see-all">
                        See all
                    </Link>
                ) }
            </div>
        </>
    );
}

export default Notifications;
