import { useState } from "react"
import { Navbar } from "../components/Navbar"
import { LayoutContext } from '../contexts/LayoutContext';
import { useSelector } from "react-redux";

export const Layout = (props) => {
    const [ layoutState, setLayoutState ] = useState({ hideNav: false, showMobileNavModal: false, showNotifications: false, showSideBar: false,  showWalletSidebar: false });
    const auth = useSelector((x) => x.auth);


    if (!auth.isLoggedIn) {
        return (
            <div className="container mx-auto">
                { props.children }
            </div>
        )
    }


    return (
        <div>
            <LayoutContext.Provider value={{ layoutState, setLayoutState }}>
                { !layoutState.hideNav && (
                  <Navbar {...props} />
                ) }
                <div className="container mx-auto">
                    { props.children }
                </div>
            </LayoutContext.Provider>
        </div>
    )
}