import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import MarketService from '../../services/MarketService';
import ChainService from '../../services/ChainService';
import { cloneDeep } from '@stacks/transactions';

export const InitialState = {
    transaction: { intervalLoader: false, loader: false, data: { result: [], meta: { mempool: {}, transactions: {} }} }
};

export const getTransactions = createAsyncThunk(
    'chain/transactions',
    async (name, x) => {
        const transactions = await ChainService.getTransactions();
        return transactions;
    }
)

export const getMoreTransactions = createAsyncThunk(
    'chain/more-transactions',
    async (offset, x) => {
        const transaction = x.getState().chain.transaction;
        try {
            const response = cloneDeep({ ...transaction });
            const txs = await ChainService.getMoreTransactions(offset);
            response.data.meta.transactions.offset = offset
            response.data.meta.transactions.total = txs.meta.transactions.total;
            response.data.result.push(...txs.result);
            response.data.meta.transactions.fetchedTotal = response.data.result.filter((x) => !x.mempool)?.length;
            response.data.meta.transactions.isAllFetched = response.data.meta.transactions.fetchedTotal === response.data.meta.transactions.total;
            return response;
        } catch (error) {
            console.log(error);
            return transaction;
        }
    }
)


const chainSlice = createSlice({
    name: 'chain',
    initialState: InitialState,
    reducers: {
        setTransactionIntervalLoader (state, action) {
            state.transaction.intervalLoader = action.payload;
        },
        setTransactionLoader (state, action) {
            state.transaction.loader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.transaction = { intervalLoader: false, loader: false, data: action.payload };
        })
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.transaction = InitialState.transaction;
        })
        builder.addCase(getMoreTransactions.fulfilled, (state, action) => {
            state.transaction = { intervalLoader: true, loader: false, data: action.payload.data };
        })
        builder.addCase(getMoreTransactions.rejected, (state, action) => {
            state.transaction = state.transaction;
        })
    },
})

export const { setTransactionLoader, setTransactionIntervalLoader } = chainSlice.actions
export default chainSlice.reducer
