import { useEffect, useState } from 'react'
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { VLineChart } from '../Charts/VLineChart'
import { SimpleLineOptions } from '../Charts/defaultOptions'
import { getPools, setPoolLoader } from '../../store/slices/poolSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAccumelatedPoolStats, getAccumelatedStats } from '../../store/slices/statsSlice'
import Loader from '../Loader'
import { commarize } from '../../helper'

export default function PoolDetailsWidget(props) {
    const [ pool, setPool ] = useState({ label: "STX-xUSD", id: '1', token0ImageUrl: 'https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg', token1ImageUrl: 'https://raw.githubusercontent.com/VelarBTC/token-images/main/xUSD.svg' })
    const [ type, setType ] = useState({ label: 'Total Value Locked', key: 'tvl' })
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);
    const poolStore = useSelector((x) => x.pool);
    const tx = stats.accumelatedPoolTransactions;

    useEffect(() => {
        dispatch(getAccumelatedPoolStats({ poolId: pool.id, type: type.key }));
    }, [pool.id, filter.calendar?.range, type.key, filter.refresh])

    useEffect(() => {
        dispatch(setPoolLoader(true));
        dispatch(getPools());
    }, []);

    useEffect(() => {

        if (poolStore.pools?.message?.length > 0) {
            const poolInfo = poolStore.pools?.message[0];
            setPool({ label: poolInfo?.symbol, key: poolInfo?.id, ...poolInfo });
        }

    }, [poolStore.pools?.message?.length])

    console.log(pool);

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-4 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Pool Details</h1>
                    <p className='text-gray text-sm'>TVL, Volume and Fees of each active pool</p>
                </div>
                <div className="right flex flex-row justify-between items-center">
                    { tx.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    ) }
                    <ElipseIcon />
                </div>
            </div>
            <div className='actions mb-2'>
                <button id="sp-tpool" data-dropdown-toggle="sp-dropdownpool" className="w-full justify-between border-slate-200 text-white bg-white border font-medium rounded-lg text-sm px-5 py-2.5 pl-3 text-center inline-flex items-center" type="button">
                    <div className='flex flex-row gap-1 items-center'>
                        { pool.token0ImageUrl && (
                            <div className="icons items-center flex">
                                <img
                                    className="z-6 poolImage"
                                    src={pool.token0ImageUrl}
                                    alt="coin two" />
                                <img
                                    style={{ left: -9 }}
                                    className="z-7 poolImage"
                                    src={pool.token1ImageUrl}
                                    alt="coin one" />
                            </div>
                        ) }
                        <span style={{ color: '#555' }}>{ pool.label }</span>
                    </div>
                    <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <div id="sp-dropdownpool" className="w-full z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-gray-700 w-full dark:text-gray-200" aria-labelledby="sp-t">
                        {poolStore.pools?.message.map((pool, index) => {
                            return (
                                <li onClick={(e) => {
                                    document.getElementById('sp-tpool').click();
                                    e.preventDefault();
                                    setPool({ key: pool.id, label: pool.symbol, ...pool })
                                }} key={index} className='hover:bg-[#F2F2F2]-800'>
                                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{ pool.symbol }</a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className='actions mb-4'>
                <button id="sp-tacc-pool" data-dropdown-toggle="spacc-pool-dropdown" className="w-full justify-between border-slate-200 text-white bg-white border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                    <div className='flex flex-row gap-2 items-center'>
                        <span style={{ color: '#555' }}>{ type.label }</span>
                    </div>
                    <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <div id="spacc-pool-dropdown" className="w-full z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-gray-700 w-full dark:text-gray-200" aria-labelledby="sp-t">
                        <li  className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-tacc-pool').click();
                                e.preventDefault();
                                setType({ key: 'tvl', label: 'Total Value Locked' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Total Value Locked</a>
                        </li>
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-tacc-pool').click();
                                e.preventDefault();
                                setType({ key: 'volume', label: 'Total Volume' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Total Volume</a>
                        </li>
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('sp-tacc-pool').click();
                                e.preventDefault();
                                setType({ key: 'fees', label: 'Total Fees' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Total Fees</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='titles-actions flex justify-between items-center'>
                <div className='mb-4'>
                    <h1 style={{ color: '#999' }} className='font-normal text-sm'>{ type.label } (in USD)</h1>
                    <h2 className='text-lg font-bold-md'>${ commarize(tx.data.accumelated?.value || 0) }</h2>
                </div>
                {/* <div className='actions'>
                    <div class="inline-flex rounded-md shadow-sm" role="group">
                        <button type="button" class="px-3 btn-sm py-2 text-sm font-sm text-gray-900 bg-white bg-[aquamarine] border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-gray focus:z-10 focus:ring-0 focus:ring-gray focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                            TVL
                        </button>
                        <button type="button" class="px-3 btn-sm py-2 text-sm font-sm text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-gray focus:z-10 focus:ring-0 focus:ring-gray focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                            Volume
                        </button>
                        <button type="button" class="px-3 btn-sm py-2 text-sm font-sm text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-gray focus:z-10 focus:ring-0 focus:ring-gray focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                            Fees
                        </button>
                    </div>
                </div> */}
            </div>
            <div className='body'>
                <div className='chart mb-2'>
                    <VLineChart 
                        options={SimpleLineOptions({ dollar: '$' }).options}
                        datasetOptions={SimpleLineOptions({ dollar: '$' }).datasetOptions}
                        rootClassName={'h-[180px]'}
                        labels={tx.data.data.map((x) => x.datetime)} 
                        records={tx.data.data.map((x) => x.value)} />   
                </div>
            </div>
        </div>
    )
}
