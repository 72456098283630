import { insightHttp } from "../utils/axiosProvider";

class CodeService {
    constructor () {}

    async getCodes () {
        const codes = (await insightHttp.get('/codes')).data;
        return codes;
    }

    async generateCode () {
        const status = (await insightHttp.post('/codes')).data;
        return status;
    }

    async deleteCode (id) {
        const status = (await insightHttp.delete(`/codes/${id}`)).data;
        return status;
    }
}

export default (new CodeService());