import { Link, NavLink, useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
import { useContext, useState } from "react";
import { LayoutContext } from "../contexts/LayoutContext";
import { useConnect } from "../hooks/useConnect";
import { useDispatch, useSelector } from "react-redux";
import { getPools, setPoolLoader } from "../store/slices/poolSlice";
import { getShortName, stringToColour } from "../helper";
import { DateRange } from 'react-date-range';
import { ModalCalendar } from "./ModalCalendar";
import moment from "moment";
import { setCalendar, setNetwork, setRefresh } from "../store/slices/filterSlice";
import Loader from "./Loader";
import { initFlowbite } from 'flowbite'

export const Navbar = (props) => {
    const dispatch = useDispatch();
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [ range, setRange ] = useState('all');
    const [ rangeLabel, setLabelRange ] = useState('All Time');
    const [ network, handleNetwork ] = useState({ label: 'Dharma Testnet', value: 'testnet' });
    const filter = useSelector((x) => x.filter);
    const stats = useSelector((x) => x.stats);
    const connect = useConnect();
    const auth = useSelector((x) => x.auth);
    const navigate = useNavigate();

    let menus = [
        {
            "name": "Signup Codes",
            "url": "/signup-codes",
            "showIf": auth?.authData?.is_super_admin
        },
    ]

    const handleFilterChange = (e, range) => {
        e.preventDefault();
        document.getElementById('filters').click();
        setLabelRange(range.label);
        setRange(range.range);
        if (range.range !== 'custom') {
            dispatch(setCalendar(range))
        }
    }

    const handleNetworkChange = (e, _netowrk) => {
        e.preventDefault();
        document.getElementById('network').click();
        handleNetwork(_netowrk);
        dispatch(setNetwork(_netowrk.value))

        setTimeout(() => {
            initFlowbite();
        }, 1000)
    }

    const networks = [
        { label: 'Dharma Mainnet', value: 'mainnet' },
        { label: 'Dharma Testnet', value: 'testnet' },
    ]

    const ranges = [
        {
            label: 'All Time',
            range: 'all',
            dateRange: [moment().subtract(1, 'years').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')]
        },
        {
            label: 'Today',
            range: 'today',
            dateRange: [
                new Date(),
                new Date(),
            ]
        },
        {
            label: 'Yesterday',
            range: 'yesterday',
            dateRange: [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days')
            ]
        },
        {
            label: 'Last 7 Days',
            range: '7',
            dateRange: [
                moment().subtract(7, 'days'),
                new Date()
            ]
        },
        {
            label: 'Last 30 Days',
            range: '30',
            dateRange: [
                moment().subtract(30, 'days'),
                new Date()
            ]
        },
        {
            label: 'Last 60 Days',
            range: '60',
            dateRange: [
                moment().subtract(60, 'days'),
                new Date()
            ]
        },
        {
            label: 'Last 90 Days',
            range: '90',
            dateRange: [
                moment().subtract(90, 'days'),
                new Date(),
            ]
        },
        {
            label: 'Custom',
            range: 'custom',
            dateRange: []
        }
    ]

    const isAppLoading = stats.usersCount.loader || stats.transactionsSplits.loader || stats.mostUsedFeatures.loader || stats.accumelatedTransactions.loader || stats.accumelatedPoolTransactions.loader || stats.popularWallets.loader || stats.popularDevices.loader || stats.userAnalyticData.loader || stats.analyticEvents.loader;
    return (
        <>
            {/* Desktop navbar */}
            <nav className="main-nav">
                <div className="container flex flex-wrap items-center justify-between mx-auto py-2 px-4 md:px-4">
                    <Link to="/" className="flex items-center">
                        <img className="self-center h-5" src="/assets/imgs/velar-logo.svg" alt="Velar logo" />
                    </Link>
                    <div className="flex items-center md:order-2 gap-1 md:gap-2">
                        <button id="filters" data-dropdown-toggle="dropdown_filters" className="text-white bg-[#F2F2F2] hover:bg-[#F2F2F2]-800 focus:ring-4 focus:outline-none focus:ring-[#F2F2F2]-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-[#F2F2F2] dark:hover:bg-[#F2F2F2] dark:focus:ring-[#F2F2F2]" type="button">
                            <span style={{ color: '#555' }}>{ rangeLabel }</span>
                            <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                            </svg>
                        </button>
                        <div id="dropdown_filters" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                { ranges.map((range, index) => {
                                    return (
                                        <li key={index}>
                                            <a href="#" onClick={(e) => handleFilterChange(e, range)} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{ range.label }</a>
                                        </li>
                                    )
                                }) }
                            </ul>
                        </div>

                        <button id="network" data-dropdown-toggle="network_filters" className="text-white bg-[#F2F2F2] hover:bg-[#F2F2F2]-800 focus:ring-4 focus:outline-none focus:ring-[#F2F2F2]-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-[#F2F2F2] dark:hover:bg-[#F2F2F2] dark:focus:ring-[#F2F2F2]" type="button">
                            <span style={{ color: '#555' }}>{ network.label }</span>
                            <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                            </svg>
                        </button>
                        <div id="network_filters" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                {networks.map((network, index) => {
                                    return (
                                        <li key={network.value}>
                                            <a onClick={(e) => handleNetworkChange(e, network)} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{ network.label }</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <button id="profile_btn" data-dropdown-toggle="profile_profile" className="font-medium rounded-lg text-sm px-5 text-center inline-flex items-center" type="button">
                            <div style={{ backgroundColor: '#eee' }} className="rounded-full overflow-hidden flex p-5 relative w-[30px] h-[30px] text-center items-center justify-center mr-3">
                                { getShortName(auth?.authData?.name) }
                            </div>
                            <span style={{ color: '#555' }}>{ auth?.authData?.name }</span>
                            <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                            </svg>
                        </button>
                        <div id="profile_profile" className="z-10  hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="profile_btn">
                                <li>
                                    <a onClick={(e) =>  { e.preventDefault(); connect.logout(); }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Logout</a>
                                </li>
                            </ul>
                        </div>
                        { isAppLoading ? (
                            <Loader />
                        ) : (
                            <img 
                                onMouseOver={(e) => {
                                    e.target.src = '/refresh-active.svg';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.src = '/refresh.svg';
                                }}
                                onClick={(e) => {
                                    dispatch(setRefresh())
                                }}
                                className="hand font-medium rounded-lg text-sm text-center inline-flex items-center" 
                                style={{ color: 'red' }} src="/refresh.svg" />
                        ) }
                    </div>
                    <div className="md:flex justify-start w-auto order-1 ml-5 flex-1 items-stretch">
                        <ul className="flex font-medium space-x-4">
                            {menus.filter((x) => x.showIf === true).map((item, index) => 
                                <li className="flex items-center" key={index}>
                                    <NavLink to={item.url} className="text-gray nav-item" aria-current="page"> {item.name} </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
            {range === 'custom' && (
                <ModalCalendar  onClose={(e) => {
                    const startDate = moment(e[0].startDate).format('YYYY MMM DD');
                    const endDate = moment(e[0].endDate).format('YYYY MMM DD');
                    setLabelRange([ startDate, endDate ].join(' - '))
                    setRange('');
                    dispatch(setCalendar({
                        label: 'Custom',
                        range: 'custom',
                        dateRange: [startDate, endDate]
                    }))
                }} />
            )}
        </>
    )
}