import { useDispatch, useSelector } from 'react-redux';
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { getGoogleAnalyticsEventData } from '../../store/slices/statsSlice';
import { VLineChart } from '../Charts/VLineChart'
import { useEffect, useState } from 'react';
import Loader from '../Loader';
import { commarize } from '../../helper';

export default function WebsiteStatsWidget(props) {
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    useEffect(() => {
        dispatch(getGoogleAnalyticsEventData());
    }, [filter.calendar?.range, filter.network, filter.refresh])

    const analyticEvents = stats.analyticEvents.data

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-6 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Event Count</h1>
                    <p className='text-gray text-sm'>User interaction across different domains</p>
                </div>
                <div className="right flex flex-row justify-between">
                    {stats.analyticEvents.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    )}
                    <ElipseIcon />
                </div>
            </div>

            <div className='actions mb-6'>
                <div className='change flex flex-row gap-2 items-center'>
                    <p className='text-xl font-semibold'>Total Event Count: { commarize(analyticEvents?.totals || 0) }</p>
                    {/* <div className='text-black font-medium flex flex-row items-center gap-1'>
                        <span style={{ fontSize: 12 }} className='text-[#1A9882] pl-2 pr-2 bg-[#E9FAF7] text-sm font-semibold'>+19% in 7 days</span>
                    </div> */}
                </div>
            </div>

            <div className='body'>
                <div className='items flex flex-col'>
                    <div className='item flex mb-2 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <span className='text-sm'>Feature</span>
                        </div>
                        <div className='right'>
                            <span className='text-sm'>Events</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] w-[40px] h-[40px]'>
                                <img src='/assets/imgs/velar-black.svg' />
                            </div>
                            <span>Website</span>
                        </div>
                        <div className='right'>
                            <span>{ analyticEvents?.website }</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] w-[40px] h-[40px]'>
                                <img src='/assets/imgs/velar-black.svg' />
                            </div>
                            <span>App</span>
                        </div>
                        <div className='right'>
                            <span>{ analyticEvents?.app }</span>
                        </div>
                    </div>   
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] w-[40px] h-[40px]'>
                                <img src='/assets/imgs/velar-black.svg' />
                            </div>
                            <span>Blog</span>
                        </div>
                        <div className='right'>
                            <span>{ analyticEvents?.blog }</span>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    )
}
