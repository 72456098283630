import { useEffect, useMemo, useState } from 'react';
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { getMostUsedFeatureSplit, getPopularDevices } from '../../store/slices/statsSlice';
import { VLineChart } from '../Charts/VLineChart'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import { AppConstants } from '../../constants';
import sum from 'lodash/sum';
import { getPopularWallets } from '../../store/slices/statsSlice';

export default function TopWalletWdiget(props) {
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    const popularWallets = stats.popularWallets;

    useEffect(() => {
        dispatch(getPopularWallets());
    }, [filter.calendar?.range, filter.network, filter.refresh])

    const total = sum(Object.values(popularWallets.data.data || {})) || 0;
    const hiro = useMemo(() => ((popularWallets.data?.data?.hiro || 0) / total) * 100); 
    const xverse = useMemo(() => ((popularWallets.data?.data?.xverse || 0) / total) * 100); 

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-6 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Wallets</h1>
                    <p className='text-gray text-sm'>Number of transactions across different wallets</p>
                </div>
                <div className="right flex flex-row justify-between">
                    { popularWallets.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    ) }
                    <ElipseIcon />
                </div>
            </div>

            <div className='actions mb-6'>
                <div className='bars flex justify-center rounder-full overflow-hidden items-center gap-0'>
                    {(!popularWallets.loader && total > 0) && (
                        <>
                            <div 
                                data-tooltip-id="tooltip" 
                                data-tooltip-content="Hiro" 
                                style={{ width: hiro + '100%' }} className='bar rounded-bl-[10px] rounded-tl-[10px] bg-[#ff5602] w-[60%] h-[15px]'></div>
                            <div 
                                data-tooltip-id="tooltip" 
                                data-tooltip-content="Xverse" 
                                style={{ width: xverse + '100%' }} className='bar rounded-br-[10px] rounded-tr-[10px] bg-[#000] h-[15px] w-[40%]'></div>
                        </>
                    )}
                </div>
            </div>

            <div className='body'>
                <div className='items flex flex-col'>
                    <div className='item flex mb-2 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <span className='text-sm'>Feature</span>
                        </div>
                        <div className='right'>
                            <span className='text-sm'>Transactions</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-[8px] w-[40px] h-[40px]'>
                                <img src='/leather.jpg' />
                            </div>
                            <div className='flex flex-col'>
                                <span>Leather Wallet</span>
                                <span className='text-sm'>leather.io</span>
                            </div>
                        </div>
                        <div className='right'>
                            <span>{ popularWallets.data?.data?.hiro || 0}</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box p-2 rounded-[8px] bg-[#000] w-[40px] h-[40px]'>
                                <img src='/assets/imgs/xverse.svg' />
                            </div>
                            <div className='flex flex-col'>
                                <span>Xverse Wallet</span>
                                <span className='text-sm'>xverse.so</span>
                            </div>
                        </div>
                        <div className='right'>
                            <span>{ popularWallets.data?.data?.xverse || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
