import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../contexts/LayoutContext';
import { useDispatch, useSelector } from 'react-redux';
import { authHttp } from '../utils/axiosProvider';
import { updateAuth } from "../store/slices/authSlice";
import { Outlet, useNavigate } from 'react-router-dom';
import { Link, NavLink } from "react-router-dom";
import Loader from '../components/Loader';
import  { setSplashVisiblity } from '../store/slices/appSlice';

export const SplashScreen = (props) => {
    const dispatch = useDispatch();
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [ state, setState ] = useState({ email: '', password: '' });
    const [ errorState, setErrorState ] = useState({ email: '', password: '' });
    const [ globalErrorState, setGlobalErrorState ] = useState('');
    const navigate = useNavigate();
    const auth = useSelector((x) => x.auth);

    useEffect(() => {
        setTimeout(() => {
            localStorage.setItem('app', JSON.stringify({ splash: false }));
            window.location = '/';
        }, 3000);

        setLayoutState({
            ...layoutState,
            hideNav: true
        });

        return () => {
            setLayoutState({
                ...layoutState,
                hideNav: false
            });   
        }
    }, [])

    return (
        <div className='splash'>
            <section className="bg-gray-50 dark:bg-gray-900 mt-1">
                <div className="flex flex-col items-center justify-center px-6 mt-3 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="#" className="flex items-center mb-1 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="mr-2" src="/assets/imgs/login-logo.svg" alt="logo" />
                    </a>
                    <div className="w-full rounded-lg dark:border sm:max-w-md dark:bg-gray-800 dark:border-gray-700">
                        <div className='text-center mb-4 mt-6'>
                            <h1 className="text-xl mb-1 font-medium leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Welcome, { auth?.authData?.name }
                            </h1>
                            <p className='font-weight-200 text-gray-200'>Setting up the Velar analytics for you!</p>
                        </div>
                        <Loader />
                        <br />
                        <br />
                    </div>
                </div>
            </section>  
        </div>
    )
}