
import axios from 'axios';
import { Config } from '../config';


const instance = axios.create({ baseURL: Config.apiURL });
const swapHttp = axios.create({ baseURL: Config.swapApi });
const poolHttp = axios.create({ baseURL: Config.poolApi });
const stakeHttp = axios.create({ baseURL: Config.stackApi });
const faucetHttp = axios.create({ baseURL: Config.faucetApi });
const authHttp = axios.create({ baseURL: Config.authApi });
const insightHttp = axios.create({ baseURL: Config.insightApi });
const testnetInsightHttp = axios.create({ baseURL: Config.testnetInsightApi });

testnetInsightHttp.defaults.headers.post['Content-Type'] = 'application/json';
insightHttp.defaults.headers.post['Content-Type'] = 'application/json';
authHttp.defaults.headers.post['Content-Type'] = 'application/json';
poolHttp.defaults.headers.post['Content-Type'] = 'application/json';
poolHttp.defaults.headers.get['Content-Type'] = 'application/json';
swapHttp.defaults.headers.post['Content-Type'] = 'application/json';
swapHttp.defaults.headers.get['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.get['Content-Type'] = 'application/json';

export { swapHttp, poolHttp, stakeHttp, testnetInsightHttp, faucetHttp, authHttp, insightHttp };
export default instance;
