import { toast } from "react-toastify";
import { AppConstants } from "./constants";
import { createAssetInfo, createSTXPostCondition, makeContractFungiblePostCondition, makeContractSTXPostCondition, makeStandardFungiblePostCondition } from "@stacks/transactions";

export const toggleBodyScroll = (active = false) => {
  if (active) {
    document.body.classList.add('modal-opened');
  }
  else {
    document.body.classList.remove('modal-opened');
  }
}

export const copyToClipboard = (text, position = 'bottom-right') => {
  navigator.clipboard.writeText(text);
  toast(<div>
    <div className="title">Copied!</div>
  </div>, { position: position });
}

export function getShortName (name) {
  if (name) {
    const names = name.split(' ');
    if (Array.isArray(names) && names.length > 1) {
      return names[0][0] + names[1][0];
    } else if (Array.isArray(names) && names.length > 0) {
      return names[0][0];
    } else {
      return name;
    }
  }

  return '';
}

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}



export const getPureAddress = (address) => {
  return address.split('.')[0];
}


export const getContractAddressAndName = (address) => {
  if (!address) { return { address: '', name: '' } } 
  const arr = address.split('.');
  return { address: arr[0], name: arr[1] };
}

export const getFirstAndLast = (val) => {
  return `${val.substr(0, 5)} ... ${val?.substr(val?.length - 5)}`
}

export const ifNanGiveZero = (val) => {
  if (isNaN(val) || val === Infinity) {
    return 0;
  }
  return val;
}

export const stringToColour = function(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('AA' + value.toString(16)).substr(-2);
  }
  return colour;
}

export function commarize(number, toFixed = 2) {
    let postfixes = ['', 'k', 'M', 'B', 't']
    let count = 0
    while (number >= 2000 && count < postfixes.length) {
        number /= 1000
        count++
    } 
    return Number(number).toFixed(toFixed) + postfixes[count];
}

export const getTokenBasedDecimalValue = (value, tokenSymbol) => {
  const found = getTokenDetailsBySymbol(tokenSymbol);
  value = String(value).replace(/[u]/g, '');
  const val = Number(Number(value) * found.tokenDecimalNum);
  return val;
  // if (String(tokenName).toLowerCase() === 'wstx') {
  //   value = String(value).replace(/[u]/g, '');
  //   return (Number(value) * 1000000);
  // }
  // return Number(value);
}

export const getTokenBasedExcatValue = (value, tokenSymbol) => {
  if (tokenSymbol === 'stx') { tokenSymbol = 'wstx'; }
  value = String(value).replace(/[u]/g, '');
  const found = getTokenDetailsBySymbol(tokenSymbol);
  return (Number(value) / found.tokenDecimalNum);
}

const getRandomNumber = (maxNum) => {
  return Math.floor(Math.random() * maxNum);
};

export const getRandomColor = () => {
  const h = getRandomNumber(360);
  const s = getRandomNumber(100);
  const l = getRandomNumber(100);

  return `hsl(${h}deg, ${s}%, ${l}%)`;
}


export function stringify(obj) {
  const json = JSON.stringify(obj, (key, value) =>
    typeof value === "bigint" ? value.toString() + "n" : value
  );
  return json;
}

export function toJSON(sringObj) {
  return JSON.parse(sringObj);
}


export const buildPostConditions = (configs) => {
  if (!Array.isArray(configs) && configs.length === 0) {
      return [];
  }
  const postConditions = [];
  configs.forEach((config) => {
      if (AppConstants.PostConditionType.StandardSTX === config.type) {
          postConditions.push(createSTXPostCondition(config.account, config.code, config.amount))
      } else if (AppConstants.PostConditionType.ContractFungible === config.type) {
          if (!config.assetInfo.address || !config?.assetInfo?.contractName && !config?.name) {
              throw new Error ('Asset Info is required in makeContractFungiblePostCondition');
          }
          const assetInfo = createAssetInfo(config.assetInfo.address, config.assetInfo.contractName, config.assetInfo.name);
          postConditions.push(makeContractFungiblePostCondition(config.account, config.ownerContract || 'core', config.code, config.amount, assetInfo));
      } else if (AppConstants.PostConditionType.StandardSTXFungible === config.type) {
        if (!config.assetInfo.address || !config?.assetInfo?.contractName && !config?.name) {
          throw new Error ('Asset Info is required in makeContractFungiblePostCondition');
        }
        const assetInfo = createAssetInfo(config.assetInfo.address, config.assetInfo.contractName, config.assetInfo.name);
        postConditions.push(makeStandardFungiblePostCondition(config.account, config.code, config.amount, assetInfo));
      } else if (AppConstants.PostConditionType.ContractSTX === config.type) {
        postConditions.push(makeContractSTXPostCondition(config.account, config.ownerContract || 'core', config.code, config.amount))
      }
  })
  return postConditions;
}

export const getTokenDetailsByAddress = (contractAddress) => {
  const store = window.velarStore;
  const state = store.getState();
  const tokens = state?.swap?.tokens?.message || [];
  const token = tokens.find((x) => x.contractAddress === contractAddress);
  if (token) {
    return token;
  } else {
    throw new Error('Token info not found.', contractAddress);
  }
}

export const getTokenDetailsBySymbol = (symbol) => {
  if (String(symbol).length === 0) {
    return { tokenDecimalNum: 1 };
  }
  const store = window.velarStore;
  const state = store.getState();
  const tokens = state?.swap?.tokens?.message || [];
  let token = tokens.find((x) => x.symbol.toLowerCase() === symbol.toLowerCase());
  if (token) {
    return {  ...token, tokenDecimalNum: 1000000 };
  } else {
    return { tokenDecimalNum: 1 };
  }
}

export const filterDecimals = (value, symbol) => {
  value = String(value);
  value = (value.indexOf(".") >= 0) ? (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 7)) : value;
  return value;
}

export const numberKeyUp  = (e) => {
  if (['+', '-'].includes(e.key)) {
    return false;
  }
}


export const isCleanNumberInput = (e) => {
  const inputData = e.nativeEvent?.data || e.key || '';
  const value = String(e.target.value);
  if (inputData.match(/[\+\-e]/g)) {
      return false;
  }
  if (value && value[0] === '.') {
    return false;
  }

  // if (!value) {
  //   return false;
  // }
  
  return true;
}

export const cleanNumberByKeyCodeInput = (e) => {
  const inputData = e.key || '';
  if (inputData.match(/[\+\-e]/g)) {
      return false;
  }
  return true;
}

export function removeLeadingZeros(num) {
  const regex1 = /^0+[1-9]\d*/g;
  if (num && num.length > 1 && (num[0] + num[1] === '00')) {
    num = num.replace(/^0+(?!$)/g, "");
    return num;
  } 
  if (regex1.test(num)) {
    return num.substr(1, num.length);
  }
  return num;
}