import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import StatsService from '../../services/StatsService';

export const InitialState = {
    usersCount: {
        loader: false,
        data: {
            dailyUsers: { _id: '', count: 0 },
            totalUsers: { _id: '', count: 0 },
            usersCounts: [],
        }
    },
    transactionsSplits: {
        loader: false,
        data: {
            series: [],
            last7DaysTotal: 0,
            total: 0,
            change: 0
        }
    },
    mostUsedFeatures: {
        loader: false,
        data: {
            series: [],
            last7DaysTotal: 0,
            total: 0,
            change: 0
        }
    },
    accumelatedTransactions: {
        loader: false,
        data: {
            accumelated: {
                value: 0
            },
            data: []
        }
    },
    accumelatedPoolTransactions: {
        loader: false,
        data: {
            accumelated: {
                value: 0
            },
            data: []
        }
    },
    popularWallets: {
        loader: false,
        data: {
            xverse: 0,
            hiro: 0,
            other: 0
        }
    },
    popularDevices: {
        loader: false,
        data: {
            web: 0,
            mobile: 0,
            other: 0
        }
    },
    userAnalyticData: {
        loader: false,
        data: {
            records: [],
            total: 0
        }
    },
    analyticEvents: {
        loader: false,
        total: {
            totals: 0,
            app: 0,
            website: 0,
            blog: 0
        }
    }
};

export const getUserStats = createAsyncThunk(
    'stats/usersCount',
    async (name, x) => {
        const dispatch = x.dispatch;
        dispatch(setUsersCountLoader(true));

        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        const userStats = (await StatsService.getUserStats(startDate, endDate)).data;
        return userStats;
    }
)

export const getTransactionSplits = createAsyncThunk(
    'stats/splits',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setTransactionSplitLoader(true));
        const splits = (await StatsService.getTransactionSplits(startDate, endDate, filters.status)).data;
        return splits;
    }
)

export const getMostUsedFeatureSplit = createAsyncThunk(
    'stats/most_used_splits',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setMostUsedTransactionSplitLoader(true));
        const splits = (await StatsService.getMostUsedFeaturesSplits(startDate, endDate, filters.status)).data;
        return splits;
    }
)


export const getAccumelatedStats = createAsyncThunk(
    'stats/accumelated',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setAccumelatedTransacitionLoader(true));
        const acc = (await StatsService.getAccumelatedStats(startDate, endDate, filters.type)).data;
        return acc;
    }
)

export const getGoogleAnalyticsUserData = createAsyncThunk(
    'stats/analytics_user_data',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setGoogleAnalyticsUserDataLoader(true));
        const acc = (await StatsService.getAnalyticsUserData(startDate, endDate, filters.type)).data;
        return acc;
    }
)

export const getGoogleAnalyticsEventData = createAsyncThunk(
    'stats/events_analytics',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setGoogleAnalyticsEventDataLoader(true));
        const acc = (await StatsService.getAnalyticEventsData(startDate, endDate)).data;
        return acc;
    }
)

export const getAccumelatedPoolStats = createAsyncThunk(
    'stats/poolaccumelated',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setAccumelatedTransacitionPoolLoader(true));
        const acc = (await StatsService.getAccumelatedPoolStats(startDate, endDate, filters.poolId, filters.type)).data;
        return acc;
    }
)

export const getPopularDevices = createAsyncThunk(
    'stats/popular_devices',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setPopularDevicesLoader(true));
        const devices = (await StatsService.getPopularDevices(startDate, endDate)).data;
        return devices;
    }
)

export const getPopularWallets = createAsyncThunk(
    'stats/popular_wallets',
    async (filters, x) => {
        const dispatch = x.dispatch;
        const filter = x.getState().filter.calendar;
        const startDate = filter.dateRange[0];
        const endDate = filter.dateRange[1];

        dispatch(setPopularWalletLoader(true));
        const wallets = (await StatsService.getPopularWallets(startDate, endDate)).data;
        return wallets;
    }
)


const userStatsSlice = createSlice({
    name: 'stats',
    initialState: InitialState,
    reducers: {
        setPopularDevicesLoader(state, action) {
            state.popularDevices.loader = action.payload;
        },
        setGoogleAnalyticsEventDataLoader(state, action) {
            state.analyticEvents.loader = action.payload;
        },
        setGoogleAnalyticsUserDataLoader(state, action) {
            state.userAnalyticData.loader = action.payload;
        },
        setPopularWalletLoader(state, action) {
            state.popularWallets.loader = action.payload;
        },
        setUsersCountLoader(state, action) {
            state.usersCount.loader = action.payload;
        },
        setTransactionSplitLoader(state, action) {
            state.transactionsSplits.loader = action.payload;
        },
        setMostUsedTransactionSplitLoader(state, action) {
            state.mostUsedFeatures.loader = action.payload;
        },
        setAccumelatedTransacitionLoader(state, action) {
            state.accumelatedTransactions.loader = action.payload;
        },
        setAccumelatedTransacitionPoolLoader(state, action) {
            state.accumelatedPoolTransactions.loader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserStats.fulfilled, (state, action) => {
            console.log(action.payload.data);
            state.usersCount = { loader: false, data: action.payload.data };
        })
        builder.addCase(getUserStats.rejected, (state, action) => {
            state.usersCount = InitialState.usersCount;
        })

        builder.addCase(getTransactionSplits.fulfilled, (state, action) => {
            state.transactionsSplits = { loader: false, data: action.payload.data };
        })
        builder.addCase(getTransactionSplits.rejected, (state, action) => {
            state.transactionsSplits = InitialState.transactionsSplits;
        })

        builder.addCase(getMostUsedFeatureSplit.fulfilled, (state, action) => {
            state.mostUsedFeatures = { loader: false, data: action.payload.data };
        })
        builder.addCase(getMostUsedFeatureSplit.rejected, (state, action) => {
            state.mostUsedFeatures = InitialState.mostUsedFeatures;
        })

        builder.addCase(getGoogleAnalyticsUserData.fulfilled, (state, action) => {
            state.userAnalyticData = { loader: false, data: action.payload };
        })
        builder.addCase(getGoogleAnalyticsUserData.rejected, (state, action) => {
            state.userAnalyticData = InitialState.userAnalyticData;
        })

        builder.addCase(getGoogleAnalyticsEventData.fulfilled, (state, action) => {
            state.analyticEvents = { loader: false, data: action.payload };
        })
        builder.addCase(getGoogleAnalyticsEventData.rejected, (state, action) => {
            state.analyticEvents = InitialState.userAnalyticData;
        })

        builder.addCase(getAccumelatedStats.fulfilled, (state, action) => {
            state.accumelatedTransactions = { loader: false, data: action.payload };
        })
        builder.addCase(getAccumelatedStats.rejected, (state, action) => {
            state.accumelatedTransactions = InitialState.accumelatedTransactions;
        })

        builder.addCase(getAccumelatedPoolStats.fulfilled, (state, action) => {
            state.accumelatedPoolTransactions = { loader: false, data: action.payload };
        })
        builder.addCase(getAccumelatedPoolStats.rejected, (state, action) => {
            state.accumelatedPoolTransactions = InitialState.accumelatedPoolTransactions;
        })


        builder.addCase(getPopularDevices.fulfilled, (state, action) => {
            state.popularDevices = { loader: false, data: action.payload };
        })
        builder.addCase(getPopularDevices.rejected, (state, action) => {
            state.accumelatedPoolTransactions = InitialState.accumelatedPoolTransactions;
        })


        builder.addCase(getPopularWallets.fulfilled, (state, action) => {
            state.popularWallets = { loader: false, data: action.payload };
        })
        builder.addCase(getPopularWallets.rejected, (state, action) => {
            state.popularWallets = InitialState.accumelatedPoolTransactions;
        })
    },
})

export const { setGoogleAnalyticsEventDataLoader, setPopularDevicesLoader, setGoogleAnalyticsUserDataLoader, setPopularWalletLoader, setMostUsedTransactionSplitLoader, setUsersCountLoader, setAccumelatedTransacitionPoolLoader, setAccumelatedTransacitionLoader, setTransactionSplitLoader } = userStatsSlice.actions
export default userStatsSlice.reducer
