import { AppConstants } from '../constants';
import http, { insightHttp, testnetInsightHttp } from '../utils/axiosProvider';

class StatsService {
    async getUserStats (startDate, endDate) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/users/counts?startDate=${startDate}&endDate=${endDate}`);
        } else {
            records = await insightHttp.get(`/users/counts?startDate=${startDate}&endDate=${endDate}`);
        }

        return records;
    }

    async getAccumelatedStats (startDate, endDate, type) {
        const records = await insightHttp.get(`/stats/types?startDate=${startDate}&endDate=${endDate}&type=${type}`);
        return records;
    }

    async getAnalyticsUserData (startDate, endDate, type) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/google/stats/${type}?startDate=${startDate}&endDate=${endDate}`);
        } else {
            records = await insightHttp.get(`/google/stats/${type}?startDate=${startDate}&endDate=${endDate}`);
        }
        return records;
    }

    async getAnalyticEventsData (startDate, endDate, type) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/google/events/?startDate=${startDate}&endDate=${endDate}`);
        } else {
            records = await insightHttp.get(`/google/events?startDate=${startDate}&endDate=${endDate}`);
        }
        return records;
    }


    async getAccumelatedPoolStats (startDate, endDate, poolId, type) {
        const records = await insightHttp.get(`/stats/types/${poolId}?startDate=${startDate}&endDate=${endDate}&type=${type}`);
        return records;
    }

    async getTransactionSplits (startDate, endDate, status) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/stats/splits?startDate=${startDate}&endDate=${endDate}&status=${status}`);
        } else {
            records = await insightHttp.get(`/stats/splits?startDate=${startDate}&endDate=${endDate}&status=${status}`);
        }
        return records;
    }

    async getMostUsedFeaturesSplits (startDate, endDate, status) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/stats/most_user_features?startDate=${startDate}&endDate=${endDate}&status=${status}`);
        } else {
            records = await insightHttp.get(`/stats/most_user_features?startDate=${startDate}&endDate=${endDate}&status=${status}`);
        }

        return records;
    }

    async getPopularDevices (startDate, endDate) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/stats/popular_devices?startDate=${startDate}&endDate=${endDate}`);
        } else {
            records = await insightHttp.get(`/stats/popular_devices?startDate=${startDate}&endDate=${endDate}`);
        }

        return records;
    }

    async getPopularWallets (startDate, endDate) {
        const store = window.velarStore.getState().filter;
        let records = null;
        if (store.network === AppConstants.Networks.TESTNET) {
            records = await testnetInsightHttp.get(`/stats/popular_wallets?startDate=${startDate}&endDate=${endDate}`);
        } else {
            records = await insightHttp.get(`/stats/popular_wallets?startDate=${startDate}&endDate=${endDate}`);
        }

        return records;
    }
}

export default (new StatsService());