import { Config } from '../config';
import { getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, contractPrincipalCV, cvToHex, cvToJSON, hexToCV,  } from '@stacks/transactions';
import { ContractService } from './ContractService';

export class PoolContractService {
    constructor() {}

    async getTotalSupply() {
        const contractService = new ContractService(getPureAddress(Config.ContractAddresses.Pool.WSTX_VELAR), 'wstx-velar');
        this.contractService = contractService;
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        const result = await this.contractService.callReadOnly('get-total-supply', {
            sender: address,
            arguments: []
        });
        return Number(hexToCV(result.data.result).value.value);
    }
}

export default (new PoolContractService());