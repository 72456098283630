import http from '../utils/axiosProvider'
import CoreContractService from './CoreContractService';
import PoolContractService from './PoolContractService';

class ChainService {
    async getTransactions() {
        const store = window.velarStore;
        const auth = store.getState().auth;
        try {
            if (auth.isLoggedIn) {
                const account = auth.authData.stxAddress.address;
                const transaction = await Promise.all([
                    http.get(`extended/v1/address/${account}/mempool?limit=50`),
                    http.get(`extended/v1/address/${account}/transactions?limit=50`)
                ]);
                const [ mempool, transactions ] = transaction;
                const transactionsData = transactions.data;
                const mempoolData = mempool.data;
                mempoolData.results = mempoolData.results.map((x) => {
                    x.memepool = true;
                    return x;
                })
                const data = [ ...mempoolData.results, ...transactionsData.results ];
                const result = [];
                data.map((res) => {
                    if (res) {
                        result.push({ 
                            ...res,
                            memepool: res.memepool,
                            contract_call: res.contract_call,
                            feeRate: res.fee_rate, 
                            txId: res.tx_id, 
                            block_hash: res.block_hash, 
                            amount: Number(res.token_transfer?.amount) / 1000000, 
                            status: res.tx_status, sender_address: res.sender_address 
                        })
                    }
                });
                return { 
                    meta: { 
                        transactions: { isAllFetched: transactionsData.total <= 50, fetchedTotal: transactionsData.total, limit: transactionsData.limit, offset: transactionsData.offset, total: transactionsData.total }, 
                        mempool: { isAllFetched: false, fetchedTotal: transactionsData.total, limit: mempoolData.limit, offset: mempoolData.offset, total: mempoolData.total } }, 
                        result: result
                    };
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }

        // .then(([mempool, transactions]) => {
        //     const mempoolData = mempool.data;
        //     const transactionsData = transactions.data;
        //     const data = [...mempoolData.results, ...transactionsData.results];
        //     const result = [];
        //     data.map((res) => {
        //         if (res) {
        //             result.push({ feeRate: res.fee_rate, txId: res.tx_id, block_hash: res.block_hash, amount: Number(res.token_transfer?.amount) / 1000000, status: res.tx_status, sender_address: res.sender_address })
        //         }
        //     });
        // }).catch((error) => {
        // })
        // return Promise.resolve({  message: response });
    }

    async getMoreTransactions(offset) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        try {
            if (auth.isLoggedIn) {
                const account = auth.authData.stxAddress.address;
                const transaction = await Promise.all([
                    http.get(`extended/v1/address/${account}/transactions?limit=50&offset=${offset}`),
                ]);
                const [ transactions ] = transaction;
                const transactionsData = transactions.data;
                const data = [ ...transactionsData.results ];
                const result = [];
                data.map((res) => {
                    if (res) {
                        result.push({ 
                            ...res,
                            memepool: res.memepool,
                            contract_call: res.contract_call,
                            feeRate: res.fee_rate, 
                            txId: res.tx_id, 
                            block_hash: res.block_hash, 
                            amount: Number(res.token_transfer?.amount) / 1000000, 
                            status: res.tx_status, sender_address: res.sender_address 
                        })
                    }
                });
                return { 
                    meta: {
                        transactions: { isAllFetched: false, fetchedTotal: transactionsData.total, limit: transactionsData.limit, offset: transactionsData.offset, total: transactionsData.total },
                    },
                    result: result
                }
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
}

export default (new ChainService());