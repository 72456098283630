import { authenticate } from '@stacks/connect';
import { useContext, useEffect, useState } from 'react';
import { ConnectContext } from '../contexts/ConnectContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountTokens, setCoinLoader, setCoins, updateAuth } from '../store/slices/authSlice';
import { getHiroOptions, mapViralWalletState } from '../utils/walletUtils';
import { AppConstants } from '../constants';
import axiosProvider from '../utils/axiosProvider';
import { commarize } from '../helper';
import { setTokenLoader } from '../store/slices/authSlice';
import { LayoutContext } from '../contexts/LayoutContext';
import AuthService from '../services/AuthService';

export const useConnect = () => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { isLoggedIn, authData } = useContext(ConnectContext);
    const [ client, setClient ] = useState(undefined);
    const dispatch = useDispatch();
    const auth = useSelector((x) => x.auth);


    const login = (walletId = AppConstants.Wallets.Hiro, options = {}) => {
        const authOptions = getHiroOptions();
        const _options = {
            ...authOptions,
            ...options,
            onFinish: (payload) => {
                const authData = mapViralWalletState(payload, walletId)
                AuthService.saveUserAddresses(authData.stxAddress).then(() => {});
                localStorage.setItem('wallet-state', JSON.stringify(authData));
                dispatch(updateAuth({ isLoggedIn: true, authData }));
            },
            sendToSignIn: true,
        };
        authenticate(_options);
    };

    const logout = ()=>{
        localStorage.clear();
        dispatch(updateAuth({ isLoggedIn: false, authData:undefined }));
    }

    const checkExpiry = ()=>{
        const date = new Date(authData.expiry);
        if (Date.now() >= date) {
            dispatch(updateAuth({ isLoggedIn: false, authData:undefined }));
            return false;
          } else {
            return true;
          }
    }

    const useBalance = () => {
        const [ balance, setBalance ] = useState(0);

        useEffect(() => {
            if (auth.info.loader === false && auth.coinLoader === false) {
                dispatch(setTokenLoader(true));
                dispatch(setCoinLoader(true))
                dispatch(getAccountTokens());
            }
        }, [authData?.stxAddress?.address])

        useEffect(() => {
            if (auth.info.loader === false && auth.info?.stx?.balance) {
                const _balance = auth.info.stx.balance;
                setBalance((Number(_balance) / 1000000).toFixed(2));
                dispatch(setCoins([
                    // {
                    //     coin: true,
                    //     name: 'BTC',
                    //     symbol: 'btc',
                    //     imageUrl: '/assets/icons/icon-bitcoin-yellow.svg',
                    //     balance:  0,
                    //     str_balance: commarize(((Number(_balance)) * 0.000000000025).toFixed(6))
                    // },
                    {
                        coin: true,
                        name: 'STX',
                        symbol: 'wstx',
                        imageUrl: 'https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg',
                        balance:  Number(_balance) / 1000000,
                        str_balance: commarize((Number(_balance) / 1000000).toFixed(6))
                    }
                ]))
            }
        }, [auth.info?.tokens?.length ,authData?.stxAddress?.address, auth.info?.stx?.balance])

        return balance;
    }

    const useWalletExtension = () => {
        const [ walletInstall, setWalletInstall ] = useState({ xverse:false, hiro:false });
        useEffect(() => {
            let state = walletInstall;
            if(window.HiroWalletProvider){
                state.hiro = true;
                setWalletInstall({ ...state });
            }
            if(window.BitcoinProvider){
                state.xverse = true;
                setWalletInstall({ ...state });
            }
        }, [window.HiroWalletProvider, window.BitcoinProvider])

        return walletInstall;
    }

    return {
        useBalance,
        useWalletExtension,
        isLoggedIn,
        authData,
        login,
        logout,
        checkExpiry
    };
};