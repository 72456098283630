import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthService from '../../services/AuthService';
import { getRandomColor } from '../../helper';
import { cloneDeep } from 'lodash';
import StorageService from '../../services/StorageService';
import { AppConstants } from '../../constants';
import moment from 'moment';

const appPersistance = StorageService.getItem(AppConstants.LOCAL_STORAGE_KEYS.Calendar);


export const filterInitialState = {
    calendar: { label: 'All Time', range: 'all', dateRange: [moment().subtract(1, 'years').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')] },
    network: 'testnet',
    refresh: 0,
    refreshing: false
};

export const filterSlice = createSlice({
    name: 'filters',
    initialState: filterInitialState,
    reducers: {
        setCalendar (state, action) {
            state.calendar = { ...action.payload,  dateRange: [ moment(action.payload.dateRange[0]).format('YYYY/MM/DD'), moment(action.payload.dateRange[1]).format('YYYY/MM/DD') ] };
            StorageService.setItem(AppConstants.LOCAL_STORAGE_KEYS.App, { ...state });
        },
        setRefresh (state, action) {
            state.refresh = state.refresh + 1;
            state.refreshing = true;
        },
        setNetwork (state, action) {
            state.network = action.payload;
            StorageService.setItem(AppConstants.LOCAL_STORAGE_KEYS.App, { ...state });
        }
    },
    extraReducers: (builder) => {},
})

export const { setCalendar, setRefresh, setNetwork } = filterSlice.actions
export default filterSlice.reducer
