import { useDispatch, useSelector } from 'react-redux';
import ElipseIcon from '../../icons/ElipseIcon'
import TransactionBagIcon from '../../icons/TransactionBagIcon'
import { VLineChart } from '../Charts/VLineChart'
import { useEffect, useState } from 'react';
import { getAccumelatedStats, getGoogleAnalyticsUserData } from '../../store/slices/statsSlice';
import { Config } from '../../config';
import { commarize } from '../../helper';
import Loader from '../Loader';

export default function UserGrowthWidget(props) {
    const [ type, setType ] = useState({ label: 'App', value: 'app' })
    const dispatch = useDispatch();
    const stats = useSelector((x) => x.stats);
    const filter = useSelector((x) => x.filter);

    useEffect(() => {
        dispatch(getGoogleAnalyticsUserData({ type: type.value }));
    }, [type.key, filter.calendar?.range, type.value, filter.refresh])

    return (
        <div className='transaction-split' style={{ position: 'relative' }}>
            <div className="widget-header mb-6 flex flex-row justify-between items-center">
                <div className="left">
                    <h1 className='font-semibold text-xl'>Users by countries</h1>
                    <p className='text-gray text-sm'>Total number of users by each country</p>
                </div>
                <div className="right flex flex-row justify-between">
                    {stats.userAnalyticData.loader && (
                        <div>
                            <Loader style={{ marginRight: 15 }} />
                        </div>
                    )}
                    <ElipseIcon />
                </div>
            </div>

            <div className='actions mb-5'>
                <button id="ug-t" data-dropdown-toggle="ug-dropdown" className="w-full justify-between border-slate-200 text-white bg-white border font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center" type="button">
                    <div className='flex flex-row gap-2 items-center'>
                        <span style={{ color: '#555' }}>{ type.label }</span>
                    </div>
                    <svg className="w-2.5 h-2.5 ml-2.5" color="#555" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                    </svg>
                </button>
                <div id="ug-dropdown" className="w-full z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul className="py-2 text-sm text-gray-700 w-full dark:text-gray-200" aria-labelledby="sp-t">
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('ug-t').click();
                                e.preventDefault();
                                setType({ value: 'website', label: 'Website' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Website</a>
                        </li>
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('ug-t').click();
                                e.preventDefault();
                                setType({ value: 'app', label: 'App' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">App</a>
                        </li>
                        <li className='hover:bg-[#F2F2F2]-800'>
                            <a onClick={(e) => {
                                document.getElementById('ug-t').click();
                                e.preventDefault();
                                setType({ value: 'blog', label: 'Blog' })
                            }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Blog</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='actions mb-5'>
                <div className='change flex flex-row gap-2 items-center'>
                    <p className='text-xl font-semibold'>Total Users: { commarize(stats.userAnalyticData.data.total) }</p>
                    {/* <div className='text-black font-medium flex flex-row items-center gap-1'> */}
                        {/* <span style={{ fontSize: 12 }} className='text-[#1A9882] pl-2 pr-2 bg-[#E9FAF7] text-sm font-semibold'>+19% in 7 days</span> */}
                    {/* </div> */}
                </div>
            </div>

            <div className='body'>
                <div className='items flex flex-col'>
                    <div className='item flex mb-2 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <span className='text-sm'>Country</span>
                        </div>
                        <div className='right'>
                            <span className='text-sm'>Users</span>
                        </div>
                    </div>
                    <div className='max-h-[220px] overflow-y-scroll'>
                        { stats.userAnalyticData.data?.records.map((info, index) => {
                            return (
                                <div key={index} className='item flex mb-5 flex-row items-center justify-between gap-2'>
                                    <div className='left flex flex-row justify-between gap-2 items-center'>
                                        <div className='box w-[40px]' style={{ border: '1px solid #eee' }}>
                                            <img style={{ borderRadius: 5 }} src={ Config.insightApi + info.img } />
                                        </div>
                                        <span>{ info.name }</span>
                                    </div>
                                    <div className='right'>
                                        <span>{ info.value }</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-full w-[40px] h-[40px]'>
                                <img src='/assets/imgs/japan.svg' />
                            </div>
                            <span>Japan</span>
                        </div>
                        <div className='right'>
                            <span>3,0000</span>
                        </div>
                    </div> */}
                    {/* <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-full w-[40px] h-[40px]'>
                                <img src='/assets/imgs/france.svg' />
                            </div>
                            <span>France</span>
                        </div>
                        <div className='right'>
                            <span>4000</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-full w-[40px] h-[40px]'>
                                <img src='/assets/imgs/germany.svg' />
                            </div>
                            <span>Germany</span>
                        </div>
                        <div className='right'>
                            <span>500</span>
                        </div>
                    </div>
                    <div className='item flex mb-5 flex-row items-center justify-between gap-2'>
                        <div className='left flex flex-row justify-between gap-2 items-center'>
                            <div className='box rounded-full w-[40px] h-[40px]'>
                                <img src='/assets/imgs/s-korea.svg' />
                            </div>
                            <span>South Korea</span>
                        </div>
                        <div className='right'>
                            <span>200</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
