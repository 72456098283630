import * as Chart from 'chart.js';
import { commarize } from '../../helper';

export default class DoughnutTextInside extends Chart.DoughnutController {
  draw(ease) {
    Chart.controllers.DoughnutController.prototype.draw.call(this, ease);
    var width = this.chart.width,
      height = this.chart.height;

    const dataset = this.chart.config._config.data.datasets[0];

    var fontSize = 12;
    this.chart.ctx.font = "normal "+ fontSize + "px Inter";
    this.chart.ctx.fontWeight = "normal";
    this.chart.ctx.textBaseline = "middle";
    this.chart.ctx.fillStyle = "#667085";

    const text = `TOTAL Txn`;
    const textX = Math.round((width - this.chart.ctx.measureText(text).width) / 2);
    const textY = height / 2.5;

    this.chart.ctx.fillText(text, textX, textY);

    let totalCenter = 2.0;
    if (text.length > 10) {
      totalCenter = 2.1 + (text.length / 100);
    }

    const text2 = commarize(dataset.total, 0);
    const textX2 = Math.round((width - this.chart.ctx.measureText(text).width) / totalCenter);
    const textY2 = height / 1.9;
    fontSize = 32;
    this.chart.ctx.font = "bold "+ fontSize + "px Inter";
    this.chart.ctx.fillStyle = "#333";
    this.chart.ctx.fillText(text2, textX2, textY2);

    // const change = Number(dataset.change).toFixed(2);
    // let op = change < 0 ? '' : '+';

    // const text3 = op + '' + change + `% in 7d`;

    // let changeCenter = 1.2;
    // if (text3.length > 10) {
    //   changeCenter = 1.25 + (text3.length / 100);
    // }


    // const textX3 = Math.round((width - this.chart.ctx.measureText(text).width) / changeCenter);
    // const textY3 = height / 1.6;

    // fontSize = 12;
    // this.chart.ctx.font = "normal "+ fontSize + "px Inter ,sans-serif";
    // if (change < 0) {
    //   this.chart.ctx.fillStyle = "red";
    // } else {
    //   this.chart.ctx.fillStyle = "#1A9882";
    // }

    // this.chart.ctx.fillText(text3, textX3, textY3);

  }
}
DoughnutTextInside.id = 'doughnut-textinside';
DoughnutTextInside.defaults = Chart.DoughnutController.defaults;
Chart.Chart.register(DoughnutTextInside);
