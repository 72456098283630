import React from 'react';
import UserStatsWidget from '../components/widgets/UserStatsWidget';
import TransactionSplitWidget from '../components/widgets/TransactionSplitWidget';
import AccumelatedStatsWidget from '../components/widgets/AccumelatedStatsWidget';
import PoolDetailsWidget from '../components/widgets/PoolDetailsWidget';
import MostUsedFeaturedWidget from '../components/widgets/MostUserFeaturedWidget';
import TopWalletWdiget from '../components/widgets/TopWalletWidget';
import PopularDevices from '../components/widgets/PopularDevices';
import UserGrowthWidget from '../components/widgets/UserGrowthWidget';
import SocialMediaImpression from '../components/widgets/SocialMediaImpression';
import WebsiteStatsWidget from '../components/widgets/WebsiteStatsWidget';
import { useSelector } from 'react-redux';
import { AppConstants } from '../constants';

export const Dashboard = (props) => {
    const app = useSelector((x) => x.app);
    const filter = useSelector((x) => x.filter);

    if (app.splash) {
        return '';
    }

    return (
        <div className='dashboard'>
            <div className='flex flex-col md:flex-row gap-5 mt-20'>
                <div className='flex-1 md:flex-[0.75] dashboard-widget'>
                    <UserStatsWidget />
                </div>
                <div className='flex-1 md:flex-[0.35] w-full dashboard-widget'>
                    <TransactionSplitWidget />
                </div>          
            </div>

            <div className='flex flex-col md:flex-row gap-5'>
                <div className='flex-[0.34]'>
                    { filter.network === AppConstants.Networks.MAINNET && (
                        <div style={{ height: 465 }} className='w-full dashboard-widget mb-5'>
                            <AccumelatedStatsWidget />
                        </div>
                    )}

                    <div style={{ height: 278 }} className='w-full dashboard-widget mb-5'>
                        <TopWalletWdiget />
                    </div>

                    <div style={{ height: 475 }} className='w-full dashboard-widget mb-5'>
                        <UserGrowthWidget />
                    </div>
                </div>

                <div className='flex-[0.34]' style={{ height: '100%' }}>
                    { filter.network === AppConstants.Networks.MAINNET && (
                        <div style={{ height: 465 }} className='w-full dashboard-widget mb-5'>
                            <PoolDetailsWidget />
                        </div>
                    ) }

                    <div style={{ height: 278 }} className='w-full dashboard-widget mb-5'>
                        <PopularDevices />
                    </div>
                    { filter.network === AppConstants.Networks.TESTNET && (
                        <div style={{ height: 475 }} className='w-full dashboard-widget mb-5'>
                            <WebsiteStatsWidget />
                        </div>
                    ) }
                </div>

                <div className='flex-[0.34]' style={{ height: '100%' }}>
                    <div style={{ height: filter.network === AppConstants.Networks.MAINNET ?  400 : 465 }} className='w-full dashboard-widget mb-5'>
                        <MostUsedFeaturedWidget />
                    </div>
                    { filter.network === AppConstants.Networks.MAINNET && (
                        <div style={{ height: 360 }} className='w-full dashboard-widget mb-5'>
                            <WebsiteStatsWidget />
                        </div>
                    ) }
                </div>
            </div>
        </div>

      
    )
}