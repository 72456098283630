import { isMobile } from '@stacks/connect';
import http, { poolHttp } from '../utils/axiosProvider'
import AuthService from './AuthService';
import CoreContractService from './CoreContractService';
import PoolContractService from './PoolContractService';

class PoolService {
    async getPools() {
        const pools = (await poolHttp.get('/pool')).data;
        return Promise.resolve(pools);
    }

    async getPoolBySymbol(symbol) {
        const data = (await poolHttp.get('/pool/' + symbol)).data;
        // const data = {
        //     "statusCode": 200,
        //     "message": {
        //     "_id": "648e0ad1395c1c9758bb65c6",
        //     "symbol": "wSTX-VELAR",
        //     "token0Symbol": "wSTX",
        //     "token0ContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx",
        //     "token0AssetName": "stx",
        //     "token1Symbol": "VELAR",
        //     "token1ContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.velar",
        //     "token1AssetName": "velar",
        //     "lpTokenContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx-velar",
        //     "lpTokenSymbol": "wSTX-VELAR",
        //     "id": "1",
        //     "lpTokenDecimal": "u0",
        //     "token0ImageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg",
        //     "token1ImageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/velar.jpg",
        //     "token0Decimail": "u6",
        //     "token1Decimail": "u6",
        //     "token0DecimalNum": 1000000,
        //     "token1DecimalNum": 1000000,
        //     "lpTokenDecimalNum": 1,
        //     "lpTokenAssetName": "lp-token",
        //     "__v": 0,
        //     "createdAt": "2023-06-17T19:34:41.701Z",
        //     "updatedAt": "2023-06-17T19:34:41.701Z"
        //     }
        // };
        data.message.name = data.message.lpTokenSymbol;
        return Promise.resolve(data);
    }

    async saveTransaction (data) {
        const payload = {
            "publicAddress": data.publicAddress,
            "token0Symbol": data.token0Symbol,
            "token0ContractAddress": data.token0ContractAddress,
            "token0Quantity": String(data.token0Quantity),
            "token1Symbol": data.token1Symbol,
            "token1ContractAddress": data.token1ContractAddress,
            "token1Quantity": String(data.token1Quantity),
            "lpTokenQuantity": String(data.lpTokenQuantity),
            "lpTokenSymbol": data.lpTokenSymbol,
            "transactionHash": data.transactionHash,
            "status": 'success',
            "slippage": String(data.slippage),
            "poolId": String(data.poolId),
            "txResponse": data.txResponse,
            "walletInUse": data.walletInUse || 'hiro',
            "txType": data.txType,
            "deviceType": isMobile() ? 'mobile' : 'web',
        };

        await poolHttp.post('/pool/transactions', payload);
    }
}

export default (new PoolService());